import React, { useMemo } from 'react'

import { DateTimeRangePicker as MUIDateTimeRangePicker } from '@mui/x-date-pickers-pro'

import { isValid } from 'date-fns'

import ReadOnly from 'components/common/inputs/ReadOnly'

import { useFormatDateTime } from 'hooks/useFormatDate'

import type { DateTimeRangePickerProps } from 'components/common/date/DateTimeRangePicker'

import type { DateRange, PickerValidDate } from '@mui/x-date-pickers-pro'

const DateTimeRangePicker = ({
  defaultValue,
  error,
  errorMessage,
  fullWidth,
  helperText,
  label,
  readOnly,
  readOnlyFormatter,
  ReadOnlyProps,
  slotProps,
  value,
  ...props
}: DateTimeRangePickerProps) => {
  const { format, use24HourClock, formatDateTime } = useFormatDateTime()

  const finalSlotProps = useMemo(
    () => ({
      ...slotProps,
      textField: (state) => ({
        error,
        helperText: error ? errorMessage : helperText,
        fullWidth,
        ...(typeof slotProps?.textField === 'function'
          ? slotProps.textField(state)
          : slotProps?.textField)
      })
    }),
    [slotProps, error, errorMessage, helperText, fullWidth]
  )

  if (readOnly) {
    const readOnlyValue = value ?? defaultValue

    const formattedValue = () => {
      if (!isValid(readOnlyValue[0]) && !isValid(readOnlyValue[1])) return ''

      if (readOnlyFormatter) return readOnlyFormatter(readOnlyValue)

      const firstValue = isValid(readOnlyValue[0]) ? formatDateTime(readOnlyValue[0], format) : ''
      const secondValue = isValid(readOnlyValue[1]) ? formatDateTime(readOnlyValue[1], format) : ''

      return `${firstValue} - ${secondValue}`
    }

    return <ReadOnly label={label} value={formattedValue()} {...ReadOnlyProps} />
  }

  return (
    <MUIDateTimeRangePicker
      value={value || ([null, null] as DateRange<PickerValidDate>)}
      label={label}
      format={format}
      ampm={!use24HourClock}
      {...props}
      slotProps={finalSlotProps}
    />
  )
}

export default DateTimeRangePicker
