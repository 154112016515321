import { alpha } from '@mui/material'
import { lightGreen } from '@mui/material/colors'

const definite = {
  /**
   * Main color
   * Used for contained background
   */
  MAIN: lightGreen[200],
  /**
   * Text color
   */
  CONTENT: lightGreen[900],
  /**
   * Color that keeps a contrast ratio above AA when XX.main is used as a bg. color
   */
  CONTRAST: 'white',
  /**
   * Fill background color for contained variant components in hover state
   */
  CONTAINED_HOVER_BACKGROUND: lightGreen[400],
  /**
   * Fill background color for outlined in hover state
   */
  OUTLINED_HOVER_BACKGROUND: alpha(lightGreen[400], 0.16),
  /**
   * Used for outlined variant components in resting state
   */
  OUTLINED_RESTING_BORDER: lightGreen[400]
}

export default definite
