import React from 'react'

import { useDroppable } from '@dnd-kit/core'

import type { DroppableProps } from 'components/dnd/Droppable/Droppable.types'

const Droppable = ({ id, children, style }: DroppableProps) => {
  const { isOver, setNodeRef } = useDroppable({ id })

  const styles = typeof style === 'function' ? style(isOver) : style

  return (
    <div id={id} key={id} ref={setNodeRef} style={styles}>
      {children}
    </div>
  )
}

export default Droppable
