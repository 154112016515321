import React from 'react'
import { useCallback, useMemo } from 'react'

import { List, ListSubheader } from '@mui/material'
import { get } from 'lodash'
import { useController, useFormContext } from 'react-hook-form'

import Autocomplete from 'components/common/inputs/Autocomplete'

import type { AutocompleteValue } from 'components/common/inputs/Autocomplete'
import type { FormAutocompleteGroupProps } from 'components/forms/FormAutocompleteGroup/FormAutocompleteGroup.types'

import type { ValueLabelPairWithGroup } from '@repo/et-types'

const getGroupBy = (option) => String((option as ValueLabelPairWithGroup).groupByValue)

const FormAutoCompleteGroupSelect = <
  M extends boolean | undefined = false,
  D extends boolean | undefined = false,
  F extends boolean | undefined = false
>({
  name,
  values,
  defaultValue,
  onChange,
  multiple,
  required,
  ...props
}: FormAutocompleteGroupProps<M, D, F>): JSX.Element => {
  const {
    control,
    formState: { errors }
  } = useFormContext()

  let isError = false
  let errorMessage = ''

  if (errors && get(errors, name)) {
    isError = true
    errorMessage = get(errors, name)?.message as string
  }

  const groupByValueMap = useMemo(() => {
    const groupByValueMap: Record<string, string> = {}

    values.forEach((option) => {
      const groupByValue = (option as ValueLabelPairWithGroup).groupByValue

      groupByValueMap[groupByValue] = String(option?.groupByLabel || option?.groupByValue)
    })

    return groupByValueMap
  }, [values])

  const handleRenderGroup = useCallback(
    (params) => (
      <li key={params.key}>
        <ListSubheader component="div">{groupByValueMap[params.group]}</ListSubheader>
        <List component="ul">{params.children}</List>
      </li>
    ),
    [groupByValueMap]
  )

  const sortedValues = useMemo(() => {
    let valuesToSort = [...values]

    if (defaultValue) {
      if (Array.isArray(defaultValue)) valuesToSort = [...valuesToSort, ...defaultValue]
      else valuesToSort.push(defaultValue)
    }

    return valuesToSort.sort((a, b) => Number(a.groupByValue) - Number(b.groupByValue))
  }, [values, defaultValue])

  const rules = useMemo(() => ({ required }), [required])

  const {
    field: { onChange: onValueChange, onBlur, ref, value }
  } = useController({ name, control, rules, defaultValue })

  const handleOnChange = useCallback(
    (newValue: AutocompleteValue<M>) => {
      onChange?.(newValue)
      onValueChange(newValue)
    },
    [onChange, onValueChange]
  )

  return (
    <Autocomplete
      id="autocomplete-filter"
      isError={isError}
      errorMessage={errorMessage}
      required={required}
      data-testid={`${name}-autocompletegroup`}
      limitTags={4}
      multiple={multiple}
      disableCloseOnSelect
      inputRef={ref}
      onBlur={onBlur}
      forcePopupIcon="auto"
      onChange={handleOnChange}
      defaultValue={defaultValue}
      value={value}
      values={sortedValues}
      groupBy={getGroupBy}
      renderGroup={handleRenderGroup}
      {...props}
    />
  )
}

export default FormAutoCompleteGroupSelect
