import type { ReactNode } from 'react'
import React, { useCallback, useMemo } from 'react'

import { MoreVert } from '@mui/icons-material'
import { IconButton, Tooltip } from '@mui/material'

import { GridActionsCellItem } from '@mui/x-data-grid-pro'

import DropdownButton from 'components/common/buttons/DropdownButton'

import { iconMapper } from 'components/common/DataGrid/IconWithText'

import { ACTIONS_COLUMN_ID, ACTION_BUTTON_SIZE } from 'hooks/dataGrid/constants'

import type { DropdownButtonProps } from 'components/common/buttons/DropdownButton'

import type { GridActionsColDef, GridRenderCellParams, GridRowParams } from '@mui/x-data-grid-pro'

import type { Base } from '@repo/et-types'

import type { TableAction } from 'types/dataGrid'

const useDataGridActions = <T extends Base>({
  actions,
  actionsDropdownProps,
  customActionsColumn,
  inline = false
}: {
  actions: TableAction<T>[]
  actionsDropdownProps?: DropdownButtonProps
  customActionsColumn?: (params: GridRenderCellParams<T>) => ReactNode
  inline?: boolean
}): GridActionsColDef<T> | null => {
  const getVisibleActions = useCallback(
    (row: GridRowParams<T>['row']) =>
      actions.filter((action) => (action.hidden ? !action.hidden(row) : true)),
    [actions]
  )

  const performAction = useCallback(
    (action: TableAction<T>, row: GridRowParams<T>['row']) => action.onClick(row),
    []
  )

  const columnWidth = useMemo(
    () =>
      !inline || customActionsColumn
        ? ACTION_BUTTON_SIZE + 8
        : actions?.length * ACTION_BUTTON_SIZE + 16,
    [actions, customActionsColumn, inline]
  )

  const actionsColumn: GridActionsColDef<T> = useMemo(() => {
    const result: GridActionsColDef<T> = {
      field: ACTIONS_COLUMN_ID,
      type: ACTIONS_COLUMN_ID,
      getActions: () => [],
      width: columnWidth,
      minWidth: columnWidth,
      resizable: false
    }

    if (customActionsColumn) {
      result.renderCell = (params) => customActionsColumn(params)
    } else {
      if (inline) {
        result.getActions = (params) =>
          getVisibleActions(params.row).map((action) => {
            let disabled = false

            if (action?.disabled) disabled = action.disabled(params.row)

            if (action?.hasPermission && !action.hasPermission(params.row)) disabled = true

            const tooltipLabel = (action?.tooltip && action.tooltip(params.row)) ?? action.label

            return (
              <>
                <Tooltip key={action.label} title={tooltipLabel} arrow>
                  <div>
                    <GridActionsCellItem
                      label={action.label}
                      icon={action.icon ?? iconMapper(action.iconType)}
                      disabled={disabled}
                      // eslint-disable-next-line react-perf/jsx-no-new-function-as-prop
                      onClick={() => performAction(action, params.row)}
                      size="small"
                    />
                  </div>
                </Tooltip>
              </>
            )
          })
      } else {
        result.renderCell = (params) => (
          <DropdownButton
            tooltipTitle="More"
            customButton={
              <IconButton size="small">
                <MoreVert fontSize="inherit" />
              </IconButton>
            }
            {...actionsDropdownProps}
            options={getVisibleActions(params.row).map((action) => {
              const disabled = action?.disabled?.(params.row) ?? false
              const hasPermission = action?.hasPermission?.(params.row) ?? true
              const tooltip = action?.tooltip?.(params.row) ?? action.label

              return {
                label: action.label,
                onClick: () => performAction(action, params.row),
                disabled: Boolean(disabled || !hasPermission),
                tooltip,
                ...action.menuItemProps
              }
            })}
          />
        )
      }
    }

    return result
  }, [
    actionsDropdownProps,
    columnWidth,
    customActionsColumn,
    inline,
    getVisibleActions,
    performAction
  ])

  return actionsColumn
}

export default useDataGridActions
