import type { ChangeEvent } from 'react'
import React from 'react'

import { Checkbox, FormControlLabel, FormGroup, Switch } from '@mui/material'
import { get, useController, useFormContext, useFormState } from 'react-hook-form'

import ReadOnly from 'components/common/inputs/ReadOnly'
import FormLabel from 'components/forms/FormLabel'

import type { FormSwitchProps } from 'components/forms/FormSwitch/FormSwitch.types'

const defaultType = 'switch'
const checkboxMargin = { mt: -1 }
const pointerStyle = { cursor: 'pointer' }

const FormSwitch = ({
  label,
  labelPlacement,
  name,
  required,
  disabled,
  type = defaultType,
  helperText,
  infoText,
  readOnly,
  onChange,
  ...props
}: FormSwitchProps) => {
  const { control, getValues } = useFormContext()
  const { errors } = useFormState({ control, name })

  const isError = Boolean(get(errors, name))
  const errorMessage = isError ? (get(errors, name)?.message as string) : ''

  const value = getValues(name) ?? false

  const {
    field: { onChange: onValueChange, onBlur, ref, value: fieldValue }
  } = useController({ name, control, rules: { required } })

  const renderControl = (
    fieldValue: boolean,
    fieldOnChange: (e: ChangeEvent<HTMLInputElement>, checked: boolean) => void,
    { id }: { id: string }
  ) => {
    const commonProps = {
      checked: fieldValue,
      disabled,
      id,
      ...props,
      onChange: (e: ChangeEvent<HTMLInputElement>, checked: boolean) => {
        fieldOnChange(e, checked)
        onChange?.(e, checked)
      }
    }

    return type === 'checkbox' ? <Checkbox {...commonProps} /> : <Switch {...commonProps} />
  }

  if (readOnly) {
    return (
      <ReadOnly
        label={label}
        value={
          type === 'checkbox' ? (
            <Checkbox checked={value} disabled sx={checkboxMargin} {...props} />
          ) : (
            <Switch checked={value} disabled sx={checkboxMargin} {...props} />
          )
        }
      />
    )
  }

  return (
    <FormGroup>
      <FormControlLabel
        name={name}
        ref={ref}
        label={
          <FormLabel
            disabled={disabled}
            errorMessage={errorMessage}
            isError={isError}
            label={label}
            infoText={infoText}
            helperText={helperText}
            htmlFor={name}
            style={pointerStyle}
          />
        }
        labelPlacement={labelPlacement}
        control={renderControl(fieldValue ?? false, onValueChange, { id: name })}
        onBlur={onBlur}
      />
    </FormGroup>
  )
}

export default FormSwitch
