import React, { useCallback } from 'react'

import { AutoAwesomeMotion } from '@mui/icons-material'
import { Box, CircularProgress, Typography } from '@mui/material'

import DropdownButton from 'components/common/buttons/DropdownButton'

import { DEFAULT_PAGE_SIZE } from 'hooks/dataGrid'
import { GRID_ITEM_SPACING } from 'src/theme/constants'

import type { DataGridBatchActionsProps } from 'components/common/DataGrid/DataGrid.types'

const batchContainerStyle = { display: 'flex', alignItems: 'center' }
const batchButtonMargin = { mb: GRID_ITEM_SPACING }
const batchInfoContainerStyle = { display: 'flex', alignItems: 'center', pb: 2 }

const firstRecordsTextStyle = { mr: 2, ml: 2 }
const cursorStyle = { cursor: 'pointer' }
const selectedCountTextStyle = { mr: 4, ml: 2 }

const DataGridBatchActions = ({
  batchActions,
  batchActionsDropdownProps,
  allRecordsSelected,
  onSelectAllRecords,
  onClearAllRecords,
  rowSelectionModel
}: DataGridBatchActionsProps) => {
  const renderDropdownIcon = useCallback(
    () =>
      batchActionsDropdownProps?.loading ? (
        <CircularProgress color="inherit" size={16} />
      ) : (
        <AutoAwesomeMotion />
      ),
    [batchActionsDropdownProps?.loading]
  )

  const totalRowsSelected = rowSelectionModel.length

  const renderBatchContent = () => {
    if (!onSelectAllRecords || !(totalRowsSelected >= DEFAULT_PAGE_SIZE)) return null

    if (allRecordsSelected) {
      return (
        <>
          <Typography sx={firstRecordsTextStyle}>{'First 200 records are selected'}</Typography>
          <Typography
            sx={cursorStyle}
            fontWeight={500}
            color={'primary'}
            onClick={onClearAllRecords}>
            {'Clear Selection'}
          </Typography>
        </>
      )
    }

    return (
      <>
        <Typography sx={selectedCountTextStyle}>
          <b>{`${rowSelectionModel.length}`}</b> {`records selected`}
        </Typography>
        <Typography
          sx={cursorStyle}
          fontWeight={500}
          color={'primary'}
          onClick={onSelectAllRecords}>
          {'Select all records (Maximum limit: 200)'}
        </Typography>
      </>
    )
  }

  if (!batchActions || batchActions?.length === 0) return null

  return (
    <Box sx={batchContainerStyle}>
      <DropdownButton
        options={batchActions}
        variant="outlined"
        color="primary"
        sx={batchButtonMargin}
        startIcon={renderDropdownIcon()}
        {...batchActionsDropdownProps}>
        {batchActionsDropdownProps?.children ?? 'Batch Actions'}
      </DropdownButton>
      <Box sx={batchInfoContainerStyle}>{renderBatchContent()}</Box>
    </Box>
  )
}

export default DataGridBatchActions
