import React from 'react'

import type { ReactElement } from 'react'

import {
  Add,
  Archive,
  ContentCopy,
  Delete,
  Edit,
  OpenInNew,
  PersonAdd,
  PersonOff,
  PushPin,
  RemoveRedEye,
  Unarchive
} from '@mui/icons-material'

import { Typography } from '@mui/material'

import type { DataGridIconTypes } from 'types/dataGrid'

export const mapIconTypeToColor: Record<
  DataGridIconTypes,
  'inherit' | 'default' | 'primary' | 'secondary' | 'error' | 'info' | 'success' | 'warning'
> = {
  Delete: 'error',
  Pin: 'primary',
  Edit: 'primary',
  Deactivate: 'error',
  Activate: 'primary',
  View: 'primary',
  Preview: 'primary',
  Add: 'primary',
  Archive: 'primary',
  Unarchive: 'primary',
  Clone: 'primary'
}

export const iconMapper = (icon: DataGridIconTypes): ReactElement => {
  const iconsMap: Record<DataGridIconTypes, ReactElement> = {
    Delete: <Delete color="inherit" fontSize="inherit" />,
    Pin: <PushPin color="inherit" fontSize="inherit" />,
    Edit: <Edit color="inherit" fontSize="inherit" />,
    Deactivate: <PersonOff color="inherit" fontSize="inherit" />,
    Activate: <PersonAdd color="inherit" fontSize="inherit" />,
    View: <RemoveRedEye color="inherit" fontSize="inherit" />,
    Preview: <OpenInNew color="inherit" fontSize="inherit" />,
    Add: <Add color="inherit" fontSize="inherit" />,
    Archive: <Archive color="inherit" fontSize="inherit" />,
    Unarchive: <Unarchive color="inherit" fontSize="inherit" />,
    Clone: <ContentCopy color="inherit" fontSize="inherit" />
  }

  return iconsMap[icon]
}

interface IconWithTextProps {
  icon: DataGridIconTypes
  iconLabel: string
}

const IconWithText = ({ icon, iconLabel }: IconWithTextProps): ReactElement => (
  <>
    {iconMapper(icon)}
    <Typography color="inherit">{iconLabel}</Typography>
  </>
)

export default IconWithText
