import type { ReactNode } from 'react'

import type { GridColDef, GridRenderCellParams } from '@mui/x-data-grid-pro'

/**
 * Column primitive types used within tables. Normally this is a value returned by
 * the columns accessorFn/valueGetter.
 */
export type ColumnPrimitive = string | number | boolean | null | undefined

/**
 * Column property types used within tables.
 * @const
 */
export const COLUMN_PROPERTY_TYPES = {
  ACCOUNT: 'account',
  ACTIONS: 'actions',
  BOOKING: 'booking',
  BOOLEAN: 'boolean',
  CONTACT: 'contact',
  CURRENCY: 'currency',
  CUSTOM: 'custom',
  DATE: 'date',
  DATE_TIME: 'datetime',
  HTML: 'html',
  INTEGER: 'integer',
  INTEGER_TIME: 'integertime',
  NUMBER: 'number',
  ORGANIZATION: 'organization',
  PICK_LIST: 'picklist',
  STRING: 'string',
  TEXT: 'text',
  USER: 'user'
} as const

/**
 * Column property types used within tables.
 * @type
 */
export type ColumnPropertyTypes = (typeof COLUMN_PROPERTY_TYPES)[keyof typeof COLUMN_PROPERTY_TYPES]

/**
 * Column Property Type
 * @type
 */
export type ColumnProperty<T> = Omit<GridColDef<T>, 'field'> & {
  /**
   * Accessor function for the column. Used to access a computed value from the record.
   */
  accessorFn?: (value: T) => ColumnPrimitive
  /**
   * Accessor key for the column. Used to access the value from the record.
   */
  accessorKey?: string
  /**
   * Cell renderer for the column. By default the cell will render a text node
   * the value of the accessorFn / accessorKey.
   */
  cell?: (cell: GridRenderCellParams<T, unknown>, record: T) => ReactNode
  /**
   * Helper text for the header of the column. Shown within a tooltip on hover.
   */
  headerHelperText?: string
  /**
   * Helper text for the column cell. Shown within a tooltip on hover.
   */
  helperText?: string
  /**
   * Unique identifier for the column. Used as the accessorKey if no accessorKey is
   * provided.
   */
  id: string
  /**
   * Label for the column header.
   */
  label?: string
  /**
   * Boolean representing if the column is locked.
   */
  locked?: boolean
  /**
   * Boolean representing if the column is saveable in a view.
   */
  saveable?: boolean
  /**
   * Sort key for the column. Used to sort the column.
   */
  sortKey?: string
  /**
   * Sort label for the column. Used to display the sort label.
   * If not provided the label will be used.
   */
  sortLabel?: string
  /**
   * Type of the column.
   */
  type: ColumnPropertyTypes
}
