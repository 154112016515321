import { useContext } from 'react'

import { MuiPickersAdapterContext } from '@mui/x-date-pickers-pro'
import { DateFormat, DateTimeFormat } from '@repo/utils/datetime'

import { useClockFormat } from 'src/contexts'

export const useFormatDate = () => {
  const format = DateFormat.EEE_MMM_d_yyyy

  const context = useContext(MuiPickersAdapterContext)

  const formatDate = (date: Date, format: string) => context.utils.formatByString(date, format)

  return { format, formatDate }
}

export const useFormatDateTime = () => {
  const use24HourClock = useClockFormat()
  const format = use24HourClock
    ? DateTimeFormat.EEE_MMM_d_yyyy_H_mm
    : DateTimeFormat.EEE_MMM_d_yyyy_h_mm_a

  const context = useContext(MuiPickersAdapterContext)

  const formatDateTime = (date: Date, format: string) => context.utils.formatByString(date, format)

  return { format, use24HourClock, formatDateTime }
}
