export enum ImageFileTypes {
  JPG = 'jpg',
  PNG = 'png',
  GIF = 'gif',
  BMP = 'bmp',
  SVG = 'svg'
}

export enum DocFileTypes {
  DOC = 'doc',
  DOCX = 'docx',
  XLS = 'xls',
  XLSX = 'xlsx',
  PPT = 'ppt',
  PPTX = 'pptx',
  PDF = 'pdf',
  TXT = 'txt'
}

export enum AudioFileTypes {
  MP3 = 'mp3',
  WAV = 'wav',
  OGG = 'ogg',
  FLAC = 'flac',
  AAC = 'aac',
  WMA = 'wma'
}

export enum VideoFileTypes {
  MP4 = 'mp4',
  AVI = 'avi',
  MKV = 'mkv',
  MOV = 'mov',
  WMV = 'wmv',
  FLV = 'flv',
  WEBM = 'webm'
}

export enum OtherFileTypes {
  ZIP = 'zip',
  RAR = 'rar',
  TAR = 'tar',
  GZ = 'gz',
  BZ2 = 'bz2',
  SEVENZ = '7z'
}

export const isVideoFile = (fileType: string) =>
  Object.values(VideoFileTypes).includes(fileType as VideoFileTypes)

export const isAudioFile = (fileType: string) =>
  Object.values(AudioFileTypes).includes(fileType as AudioFileTypes)

export const isImageFile = (fileType: string) =>
  Object.values(ImageFileTypes).includes(fileType as ImageFileTypes)

export const isDocFile = (fileType: string) =>
  Object.values(DocFileTypes).includes(fileType as DocFileTypes)

export const isOtherFile = (fileType: string) =>
  Object.values(OtherFileTypes).includes(fileType as OtherFileTypes)

export const mapFileIcons = (fileType: string) => {
  if (isImageFile(fileType)) return fileType

  if (isDocFile(fileType)) {
    if (fileType === 'pdf' || fileType === 'txt' || fileType === 'pptx' || fileType === 'docx') {
      return fileType
    }

    return 'doc'
  }

  if (isAudioFile(fileType)) return 'audio'

  if (isVideoFile(fileType)) return 'video'

  if (isOtherFile(fileType)) {
    if (fileType === 'zip') return fileType
  }

  return 'file'
}
