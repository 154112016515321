import React from 'react'
import { useCallback, useMemo, useState } from 'react'

import { get as lodashGet } from 'lodash'
import { useController, useFormContext, useFormState } from 'react-hook-form'

import TimePicker from 'components/common/date/TimePicker'

import type { FormTimePickerOffsetProps } from 'components/forms/FormTimePickerOffset/FormTimePickerOffset.types'

const getSecondsSinceMidnight = (date: Date): number =>
  date.getHours() * 3600 + date.getMinutes() * 60 + date.getSeconds()

const getDateFromSeconds = (seconds: number): Date => {
  const date = new Date()

  date.setHours(0, 0, 0, 0)
  date.setSeconds(isNaN(Number(seconds)) ? 0 : Number(seconds))
  return date
}

const FormTimePickerOffset = ({
  name,
  required,
  slotProps,
  onValueChange,
  ...props
}: FormTimePickerOffsetProps) => {
  const [isOpen, setIsOpen] = useState(false)
  const { control } = useFormContext()
  const { errors, defaultValues } = useFormState({ control, name })

  let isError = false
  let errorMessage = ''
  const defaultValue = lodashGet(defaultValues, name)
  const rules = useMemo(() => ({ required }), [required])

  const {
    field: { onChange, onBlur, value, ref }
  } = useController({ name, control, defaultValue, rules })

  const timeValue = useMemo(() => (value == 0 || value ? getDateFromSeconds(value) : null), [value])
  const handleOnClose = useCallback(() => setIsOpen(false), [])
  const handleOnChange = useCallback(
    (time) => {
      const seconds = getSecondsSinceMidnight(time)

      onValueChange?.(seconds, name)
      onChange(seconds)
    },
    [name, onChange, onValueChange]
  )

  const onTextFieldClick = useCallback(() => setIsOpen((prev) => !prev), [])
  const finalSlotProps = useMemo(
    () => ({
      ...slotProps,
      textField: { onBlur, onClick: onTextFieldClick, ...slotProps?.textField }
    }),
    [onBlur, slotProps, onTextFieldClick]
  )

  if (errors && lodashGet(errors, name)) {
    isError = true
    errorMessage = lodashGet(errors, name)?.message as string
  }

  return (
    <TimePicker
      {...props}
      open={isOpen}
      onClose={handleOnClose}
      disableOpenPicker
      inputRef={ref}
      name={name}
      error={isError}
      errorMessage={errorMessage}
      defaultValue={defaultValue}
      onChange={handleOnChange}
      slotProps={finalSlotProps}
      value={timeValue}
    />
  )
}

export default FormTimePickerOffset
