import React, { useCallback } from 'react'

import { InfoRounded } from '@mui/icons-material'
import { Box, FormHelperText, FormLabel as MUIFormLabel, Tooltip } from '@mui/material'

import type { FormLabelProps } from 'components/forms/FormLabel/FormLabel.types'

const labelStyles = (disabled: boolean) => ({
  color: disabled ? 'text.disabled' : 'text.primary',
  display: 'flex',
  flexWrap: 'nowrap',
  gap: 0.5
})

const errorTextStyle = { m: '0' }
const helperTextStyle = (disabled?: boolean) => ({
  color: disabled ? 'text.disabled' : 'text.secondary',
  m: '0'
})

const FormLabel = ({
  disabled,
  label,
  infoText,
  errorMessage,
  isError,
  helperText,
  ...props
}: FormLabelProps) => {
  const renderHelperText = useCallback(() => {
    if (isError && errorMessage) {
      return (
        <FormHelperText sx={errorTextStyle} className="Mui-error">
          {errorMessage}
        </FormHelperText>
      )
    }

    if (helperText) {
      return <FormHelperText sx={helperTextStyle(disabled)}>{helperText}</FormHelperText>
    }

    return null
  }, [disabled, errorMessage, helperText, isError])

  const renderInfoText = useCallback(() => {
    if (infoText) {
      return (
        <Tooltip title={infoText} arrow>
          <InfoRounded color={disabled ? 'disabled' : 'action'} fontSize="small" />
        </Tooltip>
      )
    }

    return null
  }, [infoText, disabled])

  return (
    <Box ml={0.25}>
      <MUIFormLabel sx={labelStyles(disabled)} {...props}>
        {label}
        {renderInfoText()}
      </MUIFormLabel>
      {renderHelperText()}
    </Box>
  )
}

export default FormLabel
