import React from 'react'

import { Box, Link, Typography } from '@mui/material'

import NextLink from 'next/link'

import type { CustomLinkProps } from 'components/common/CustomLink/CustomLink.types'

/**
 * Renders a custom link component built on top of MUI's Link and Typography components.
 * The MUI Link component is wrapped in a NextLink component to enable client-side navigation.
 * @param {CustomLinkProps} props - The props of the component.
 * @returns {React.ReactElement} The custom link component.
 */
const CustomLink = ({ href, children, boxProps, linkProps, typographyProps }: CustomLinkProps) => (
  <Box width="inherit" {...boxProps}>
    <Link href={href} component={NextLink} flex="1" display="flex" {...linkProps}>
      <Typography variant="body1" flex="1" color="inherit" noWrap {...typographyProps}>
        {children}
      </Typography>
    </Link>
  </Box>
)

export default CustomLink
