import { useEffect, useRef } from 'react'

export const useDidMount = () => {
  const isMountRef = useRef(false)

  useEffect(() => {
    isMountRef.current = true

    return () => {
      isMountRef.current = false
    }
  }, [])

  return isMountRef.current
}
