import type { ReactNode } from 'react'
import React, { forwardRef, useCallback, useMemo, useRef } from 'react'

import {
  FormControl,
  FormHelperText,
  InputLabel,
  ListItemText,
  MenuItem,
  Select as MuiSelect
} from '@mui/material'

import ReadOnly from 'components/common/inputs/ReadOnly'

import type { SelectProps } from 'components/common/inputs/Select'

import type { ValueLabelPair, ValueNodePair } from '@repo/et-types'

const defaultNone = { label: 'None', value: '' }
const defaultVariant = 'outlined'
const selectStyle = {
  width: '100%',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  overflow: 'hidden'
}
const listItemStyle = { whiteSpace: 'normal' }

const Select = forwardRef<HTMLDivElement, SelectProps>(
  (
    {
      name,
      values,
      value,
      required,
      onChange,
      fullWidth,
      label,
      margin,
      disabled,
      multiple,
      helperText,
      errorMessage,
      size,
      variant = defaultVariant,
      color,
      error,
      readOnly,
      shouldShowNone,
      ReadOnlyProps,
      ...props
    }: SelectProps,
    ref
  ): JSX.Element => {
    const id = `${name}-select`
    const formControlRef = useRef<HTMLDivElement>(null)

    const renderValue = useCallback(
      (selected: string | number | (string | number)[]): string | ReactNode => {
        if (multiple && Array.isArray(selected)) {
          return selected
            ?.map((e: string | number) => values.find((v) => v.value === e)?.label)
            .join(', ')
        }

        const index = values.findIndex((e: ValueNodePair | ValueLabelPair) => e.value === selected)

        return index >= 0
          ? values[index].label
          : shouldShowNone || readOnly
            ? defaultNone.label
            : ''
      },
      [multiple, values, shouldShowNone, readOnly]
    )

    const renderHelperText = useCallback(() => {
      if (errorMessage) return <FormHelperText className="Mui-error">{errorMessage}</FormHelperText>

      return helperText ? <FormHelperText>{helperText}</FormHelperText> : null
    }, [errorMessage, helperText])

    const inputProps = useMemo(() => ({ id }), [id])
    const menuProps = useMemo(
      () => ({
        PaperProps: { style: { maxHeight: '18rem' } },
        MenuListProps: { style: { width: formControlRef.current?.offsetWidth } }
      }),
      [formControlRef]
    )

    const finalValues = useMemo(
      () => (shouldShowNone || readOnly ? [defaultNone, ...values] : values),
      [shouldShowNone, values, readOnly]
    )
    const finalValue = value || (multiple ? [] : '')

    if (readOnly) {
      const readOnlyValue =
        multiple && Array.isArray(value)
          ? value?.map((e: string | number) => values.find((v) => v.value === e)?.label)
          : values.find((v) => v.value === value)?.label

      return <ReadOnly label={label} value={readOnlyValue} {...ReadOnlyProps} />
    }

    return (
      <FormControl
        ref={formControlRef}
        color={color}
        disabled={disabled}
        margin={margin}
        error={error}
        fullWidth={fullWidth}
        required={required}
        variant={variant}
        size={size}
        focused={readOnly || undefined}>
        {label && <InputLabel htmlFor={id}>{label}</InputLabel>}
        {/* @ts-expect-error -- MUISelect seems to shove "unknown" always into the value type, which breaks type checks. */}
        <MuiSelect<string | number | (string | number)[]>
          {...props}
          ref={ref}
          readOnly={readOnly}
          displayEmpty={readOnly || props.displayEmpty}
          data-testid={id}
          sx={selectStyle}
          inputProps={inputProps}
          labelId={id}
          label={label}
          onChange={onChange}
          value={finalValue}
          multiple={multiple}
          MenuProps={menuProps}
          IconComponent={readOnly ? undefined : props.IconComponent}
          renderValue={renderValue}>
          {finalValues.map((e: ValueNodePair | ValueLabelPair, index: number) => (
            <MenuItem
              key={index}
              value={e.value}
              disabled={e.disabled}
              data-testid={`${id}-option-${index}`}
              role="option">
              <ListItemText sx={listItemStyle} primary={e.label} secondary={e.helperText} />
            </MenuItem>
          ))}
        </MuiSelect>
        {renderHelperText()}
      </FormControl>
    )
  }
)

Select.displayName = 'Select'

export default Select
