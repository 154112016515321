import type { ReactElement, ReactNode } from 'react'

import type { SvgIconComponent } from '@mui/icons-material'
import type { MenuItemProps } from '@mui/material'

import type { ActionFunctionOptions } from 'types/action'

export const DATA_GRID_ICON_TYPES = {
  DELETE: 'Delete',
  PIN: 'Pin',
  EDIT: 'Edit',
  ACTIVATE: 'Activate',
  DEACTIVATE: 'Deactivate',
  VIEW: 'View',
  PREVIEW: 'Preview',
  ADD: 'Add',
  ARCHIVE: 'Archive',
  UNARCHIVE: 'Unarchive',
  CLONE: 'Clone'
} as const

export type DataGridIconTypes = (typeof DATA_GRID_ICON_TYPES)[keyof typeof DATA_GRID_ICON_TYPES]

export interface TableAction<T> {
  key: string
  label: string
  menuItemProps?: Omit<MenuItemProps, 'onClick'>
  onClick: (record: T, options?: ActionFunctionOptions) => void
  children?: ReactNode
  icon?: ReactElement<SvgIconComponent>
  iconType?: DataGridIconTypes
  disabled?: (record: T) => boolean
  tooltip?: (record: T) => string | undefined
  hidden?: (record: T) => boolean
  hasPermission?: (record: T) => boolean
}
