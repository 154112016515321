import React from 'react'

import { Box, alpha } from '@mui/material'

import Loader from 'components/common/layout/Loader'

import type { Theme } from '@mui/material'

const containerStyles = {
  backgroundColor: (theme: Theme) => alpha(theme.palette.background.paper, 0.65),
  pointerEvents: 'none'
}

const DataGridLoader = () => (
  <Box
    sx={containerStyles}
    display="flex"
    alignItems="center"
    justifyContent="center"
    height="100%"
    minHeight="12.5rem"
    p={4}>
    <Loader />
  </Box>
)

export default DataGridLoader
