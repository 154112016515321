import React from 'react'
import { useMemo } from 'react'

import { FormHelperText } from '@mui/material'

import { get as lodashGet } from 'lodash'
import { matchIsValidColor } from 'mui-color-input'
import { useController, useFormContext, useFormState } from 'react-hook-form'

import ColorTextField from 'components/common/inputs/ColorTextField'

import type { FormColorTextFieldProps } from 'components/forms/FormColorTextField/FormColorTextField.types'

const FormColorTextField = ({ name, helperText, required, ...props }: FormColorTextFieldProps) => {
  const { control } = useFormContext()
  const { errors } = useFormState({ control, name })

  let isError = false
  let errorMessage = ''

  if (errors && lodashGet(errors, name)) {
    isError = true
    errorMessage = lodashGet(errors, name)?.message as string
  }

  const rules = useMemo(() => ({ validate: matchIsValidColor, required }), [required])

  const {
    field: { onChange, onBlur, ref, value }
  } = useController({ name, control, rules })

  return (
    <>
      <ColorTextField
        {...props}
        ref={ref}
        required={required}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        error={isError}
      />
      {errorMessage ? (
        <FormHelperText className="Mui-error">{errorMessage}</FormHelperText>
      ) : (
        helperText && <FormHelperText>{helperText}</FormHelperText>
      )}
    </>
  )
}

export default FormColorTextField
