import React, { useMemo } from 'react'

import { DatePicker as MUIDatePicker } from '@mui/x-date-pickers-pro'

import { isValid } from 'date-fns'

import ReadOnly from 'components/common/inputs/ReadOnly'

import { useFormatDate } from 'hooks/useFormatDate'

import type { DatePickerProps } from 'components/common/date/DatePicker'

import type { DateView } from '@mui/x-date-pickers-pro'

const pickerViews: DateView[] = ['year', 'month', 'day']

const DatePicker = ({
  defaultValue,
  error,
  errorMessage,
  fullWidth,
  helperText,
  label,
  readOnly,
  readOnlyFormatter,
  ReadOnlyProps,
  slotProps,
  value,
  ...props
}: DatePickerProps) => {
  const { format, formatDate } = useFormatDate()

  const finalSlotProps = useMemo(
    () => ({
      ...slotProps,
      textField: (state) => ({
        error,
        helperText: error ? errorMessage : helperText,
        fullWidth,
        ...(typeof slotProps?.textField === 'function'
          ? slotProps.textField(state)
          : slotProps?.textField)
      })
    }),
    [slotProps, error, errorMessage, helperText, fullWidth]
  )

  if (readOnly) {
    const readOnlyValue = value ?? defaultValue

    const formattedValue = () => {
      if (!isValid(readOnlyValue)) return ''

      return readOnlyFormatter
        ? readOnlyFormatter(readOnlyValue)
        : formatDate(readOnlyValue, format)
    }

    return <ReadOnly label={label} value={formattedValue()} {...ReadOnlyProps} />
  }

  return (
    <MUIDatePicker
      {...props}
      value={value}
      label={label}
      format={format}
      views={pickerViews}
      slotProps={finalSlotProps}
    />
  )
}

export default DatePicker
