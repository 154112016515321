import React from 'react'

import { Typography } from '@mui/material'

import type { TypographyProps } from '@mui/material'

type MailHeaderCellProps = { prefix: string; value: string } & TypographyProps

const MailHeaderCell = ({ prefix, value, variant = 'caption', ...props }: MailHeaderCellProps) => (
  <Typography color="text.secondary" variant={variant} {...props}>
    <Typography color="text.primary" fontWeight="bold" component="span" variant="inherit">
      {prefix}:
    </Typography>{' '}
    {value}
  </Typography>
)

export default MailHeaderCell
