import React, { useEffect } from 'react'

import type { ReactNode } from 'react'

import { Breadcrumbs, Grid, Paper, Typography } from '@mui/material'

import CustomLink from 'components/common/CustomLink/CustomLink'

import { GRID_ROW_SPACING } from 'theme/constants'

import type { PageHeaderBreadCrumb, PageHeaderProps } from 'components/common/layout/PageHeader'

import type { LinkProps } from '@mui/material'

const titleIsStringOrNumber = (title: ReactNode): boolean =>
  typeof title === 'string' || typeof title === 'number'

const customLinkProps: LinkProps = { color: 'inherit', underline: 'hover' }
const actionsWrapperStyles = { display: 'flex', justifyContent: 'flex-end' }

const PageHeader = ({ title, subtitle, breadcrumbs, actions }: PageHeaderProps): JSX.Element => {
  // When the title is a string or number, set the document title
  // to the value of the title prop, if the title is a component,
  // the page using the PageHeader component should set the title
  useEffect(() => {
    if (titleIsStringOrNumber(title)) document.title = String(title)
  }, [title])

  const getBreadCrumb = (breadcrumb: PageHeaderBreadCrumb, index: number) => {
    if (typeof breadcrumb === 'string') {
      return (
        <Typography variant="caption" key={index}>
          {breadcrumb}
        </Typography>
      )
    }

    return (
      <CustomLink href={breadcrumb.href} linkProps={customLinkProps} key={index}>
        <Typography variant="caption">{breadcrumb.label}</Typography>
      </CustomLink>
    )
  }

  const hasBreadcrumb = breadcrumbs && breadcrumbs.length > 0

  const renderTitle = () => {
    if (titleIsStringOrNumber(title)) {
      return (
        <Typography variant="h5" color="primary">
          {title}
        </Typography>
      )
    }

    return title
  }

  const renderSubtitle = () => {
    if (titleIsStringOrNumber(subtitle)) {
      return <Typography variant="subtitle1">{subtitle}</Typography>
    }

    return subtitle
  }

  return (
    <Grid
      container
      alignItems="center"
      rowGap={GRID_ROW_SPACING}
      p={GRID_ROW_SPACING}
      component={Paper}
      elevation={0}
      square>
      <Grid item xs={12} sm={6}>
        <Grid container direction="column">
          {hasBreadcrumb && (
            <Grid item>
              <Breadcrumbs aria-label="breadcrumb" separator="/">
                {breadcrumbs.map((breadcrumb, index) => getBreadCrumb(breadcrumb, index))}
              </Breadcrumbs>
            </Grid>
          )}
          <Grid item>{title && renderTitle()}</Grid>
          <Grid item>{subtitle && renderSubtitle()}</Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} sm={6} display="flex" sx={actionsWrapperStyles}>
        {actions}
      </Grid>
    </Grid>
  )
}

export default PageHeader
