import React from 'react'

import type { CSSProperties } from 'react'

import { useDraggable } from '@dnd-kit/core'

import { CSS } from '@dnd-kit/utilities'

import type {
  DraggableProps,
  GetDraggableStyleProps
} from 'components/dnd/Draggable/Draggable.types'

const getDraggableStyle = ({
  transform,
  style,
  isDragging
}: GetDraggableStyleProps): CSSProperties => ({
  transform: CSS.Transform.toString({ ...transform, scaleX: 1, scaleY: 1 }),
  position: 'relative',
  zIndex: isDragging ? 1 : undefined,
  cursor: isDragging ? 'grabbing' : 'grab',
  ...style
})

const Draggable = ({ id, disabled, children, style }: DraggableProps) => {
  const { attributes, listeners, isDragging, setNodeRef, transform } = useDraggable({
    id,
    disabled
  })

  const renderChildren = () => {
    if (typeof children === 'function') return children({ isDragging })

    return children
  }

  return (
    <div
      ref={setNodeRef}
      style={getDraggableStyle({ transform, style, isDragging })}
      {...listeners}
      {...attributes}>
      {renderChildren()}
    </div>
  )
}

export default Draggable
