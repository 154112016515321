import React from 'react'

import { Box, Button, Stack } from '@mui/material'

import AddButton from 'components/common/buttons/AddButton'
import Loader from 'components/common/layout/Loader'
import Mail from 'components/mail/Mail'

import type { MailThreadProps } from 'components/mail/MailThread/MailThread.types'

const MailThread = ({
  emptyElement,
  hasMore,
  loading,
  loadMoreButtonProps,
  mails,
  mailProps,
  newEmailButtonProps,
  onLoadMoreClick,
  onNewEmailClick
}: MailThreadProps) => (
  <Stack rowGap={2}>
    <Box>
      <AddButton size="small" onClick={onNewEmailClick} {...newEmailButtonProps}>
        {newEmailButtonProps?.children ?? 'New Email'}
      </AddButton>
    </Box>
    {!loading && mails?.length === 0 && !hasMore ? emptyElement : null}
    {mails.map((mail) => (
      <Mail key={mail.id} mail={mail} {...mailProps} />
    ))}
    {hasMore && !loading ? (
      <Box display="flex" justifyContent="center">
        <Button variant="outlined" onClick={onLoadMoreClick} {...loadMoreButtonProps}>
          {loadMoreButtonProps?.children ?? 'Load More...'}
        </Button>
      </Box>
    ) : null}
    {loading ? <Loader size={32} /> : null}
  </Stack>
)

export default MailThread
