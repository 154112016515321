import React from 'react'

import { Box, Chip, Typography } from '@mui/material'

import type { ReadOnlyProps } from 'components/common/inputs/ReadOnly'

const margin = { marginRight: '.25rem' }
const textStyle = { wordBreak: 'break-word' }
const labelStyle = { width: '7.5rem', flexShrink: 0 }

const ReadOnly = ({
  children,
  className,
  direction = 'row',
  label,
  value,
  TypographyProps = {}
}: ReadOnlyProps) => {
  const renderValue = () => {
    if (Array.isArray(value) && value.length > 0) {
      return value.map((val, index) => {
        if (typeof val === 'string') {
          return <Chip key={index} label={val} sx={margin} />
        }

        return val
      })
    }

    const displayValue = value || (!children && 'None')

    return (
      <Typography
        {...TypographyProps}
        color={displayValue === 'None' ? 'text.secondary' : TypographyProps.color}
        sx={textStyle}>
        {displayValue}
      </Typography>
    )
  }

  return (
    <Box
      className={className}
      display="flex"
      flexDirection={direction}
      alignItems={direction === 'row' ? 'center' : 'flex-start'}
      gap={direction === 'row' ? 2 : 1}>
      <Typography variant="body2" color="text.secondary" sx={labelStyle}>
        {label}
      </Typography>
      <Box display="flex" flexWrap="wrap">
        {children}
        {renderValue()}
      </Box>
    </Box>
  )
}

export default ReadOnly
