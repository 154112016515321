import React, { useMemo } from 'react'

import { MultiInputTimeRangeField, SingleInputTimeRangeField } from '@mui/x-date-pickers-pro'

import { format, isValid } from 'date-fns'

import ReadOnly from 'components/common/inputs/ReadOnly'

import type { TimeRangeFieldProps } from 'components/common/date/TimeRangeField'

import type { DateRange, PickerValidDate } from '@mui/x-date-pickers-pro'

const TimeRangeField = ({
  defaultValue,
  error,
  errorMessage,
  fullWidth,
  helperText,
  label,
  multiInput = false,
  readOnly,
  readOnlyFormatter,
  ReadOnlyProps,
  slotProps,
  value,
  ...props
}: TimeRangeFieldProps) => {
  const finalSlotProps = useMemo(
    () => ({
      ...slotProps,
      textField: (state) => ({
        error,
        helperText: error ? errorMessage : helperText,
        fullWidth,
        ...(typeof slotProps?.textField === 'function'
          ? slotProps.textField(state)
          : slotProps?.textField)
      })
    }),
    [slotProps, error, errorMessage, helperText, fullWidth]
  )

  if (readOnly) {
    const readOnlyValue = value ?? defaultValue

    const formattedValue = () => {
      if (!isValid(readOnlyValue[0]) && !isValid(readOnlyValue[1])) return ''

      if (readOnlyFormatter) return readOnlyFormatter(readOnlyValue)

      const firstValue = isValid(readOnlyValue[0]) ? format(readOnlyValue[0], 'h:mm a') : ''
      const secondValue = isValid(readOnlyValue[1]) ? format(readOnlyValue[1], 'h:mm a') : ''

      return `${firstValue} - ${secondValue}`
    }

    return <ReadOnly label={label} value={formattedValue()} {...ReadOnlyProps} />
  }

  const Component = multiInput ? MultiInputTimeRangeField : SingleInputTimeRangeField
  const componentValue = value || ([null, null] as DateRange<PickerValidDate>)

  return (
    // @ts-expect-error -- We are passing the correct props to the component
    // Typescript doesn't like the conditional types on the props.
    <Component {...props} value={componentValue} label={label} slotProps={finalSlotProps} />
  )
}

export default TimeRangeField
