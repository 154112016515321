import { createContext, useContext } from 'react'

export const ClockFormatContext = createContext<boolean | undefined>(undefined)

export const useClockFormat = () => {
  const context = useContext(ClockFormatContext)

  if (context === undefined) {
    throw new Error('useClockFormat must be used within a ClockFormatProvider')
  }

  return context
}
