import React, { forwardRef, useCallback } from 'react'

import { FormControl, FormControlLabel, Radio as MUIRadio } from '@mui/material'

import FormLabel from 'components/forms/FormLabel'

import type { RadioProps } from 'components/common/buttons/Radio'

const controlComponent = <MUIRadio />

const Radio = forwardRef(
  (
    {
      disabled,
      errorMessage,
      helperText,
      infoText,
      isError,
      label,
      onChange,
      readOnly,
      required,
      value,
      ...props
    }: RadioProps,
    ref
  ) => {
    const handleOnClick = useCallback(() => {
      if (!readOnly && !disabled && onChange) onChange(!value)
    }, [disabled, onChange, readOnly, value])

    const isDisabled = disabled || readOnly

    return (
      <FormControl>
        <FormControlLabel
          control={controlComponent}
          onClick={handleOnClick}
          checked={Boolean(value)}
          value={value}
          disabled={isDisabled}
          ref={ref}
          label={
            <FormLabel
              disabled={disabled}
              errorMessage={errorMessage}
              isError={isError}
              label={label}
              infoText={infoText}
              helperText={helperText}
              required={required}
              focused={false}
            />
          }
          {...props}
        />
      </FormControl>
    )
  }
)

Radio.displayName = 'Radio'

export default Radio
