import React, { useMemo } from 'react'

import { get as lodashGet } from 'lodash'
import { useController, useFormContext, useFormState } from 'react-hook-form'

import Radio from 'components/common/buttons/Radio'

import type { FormRadioProps } from 'components/forms/FormRadio/FormRadio.types'

const FormRadio = ({ name, required, ...props }: FormRadioProps) => {
  const { control } = useFormContext()
  const { errors } = useFormState({ control, name })

  let isError = false
  let errorMessage = ''

  if (errors && lodashGet(errors, name)) {
    isError = true
    errorMessage = lodashGet(errors, name)?.message as string
  }

  const rules = useMemo(() => ({ required: required }), [required])
  const {
    field: { onChange, onBlur, ref, value }
  } = useController({ name, control, rules })

  return (
    <Radio
      {...props}
      name={name}
      ref={ref}
      onChange={onChange}
      required={required}
      onBlur={onBlur}
      value={value}
      isError={isError}
      errorMessage={errorMessage}
    />
  )
}

export default FormRadio
