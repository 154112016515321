import React, { useCallback } from 'react'

import { ListItemIcon, ListItemText, MenuItem, Tooltip } from '@mui/material'

import type { DropdownButtonItemProps } from 'components/common/buttons/DropdownButton/DropdownButton.types'

const DropdownButtonItem = ({ index, option, onClick }: DropdownButtonItemProps) => {
  const handleItemClick = useCallback((e) => onClick(e, option?.onClick), [onClick, option])

  return (
    <Tooltip arrow placement="bottom" key={index} title={option.tooltip}>
      <div>
        <MenuItem key={index} {...option} onClick={handleItemClick}>
          {option.icon ? <ListItemIcon>{option.icon}</ListItemIcon> : null}
          <ListItemText>{option.label}</ListItemText>
        </MenuItem>
        {option.children}
      </div>
    </Tooltip>
  )
}

export default DropdownButtonItem
