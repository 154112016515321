import React from 'react'
import { useMemo } from 'react'

import { isValid, parseISO } from 'date-fns'
import { get as lodashGet } from 'lodash'
import { useController, useFormContext, useFormState } from 'react-hook-form'

import TimePicker from 'components/common/date/TimePicker'

import type { FormTimePickerProps } from 'components/forms/FormTimePicker/FormTimePicker.types'

const FormTimePicker = ({
  name,
  required,
  slotProps,
  defaultValue: propDefaultValue,
  ...props
}: FormTimePickerProps) => {
  const { control } = useFormContext()
  const { errors, defaultValues } = useFormState({ control, name })
  const formDefaultValue = lodashGet(defaultValues, name)

  let isError = false
  let errorMessage = ''

  const rules = useMemo(() => ({ required }), [required])
  const defaultValue = useMemo(
    () => propDefaultValue || formDefaultValue || null,
    [propDefaultValue, formDefaultValue]
  )

  const {
    field: { onChange, onBlur, value, ref }
  } = useController({ name, control, defaultValue, rules })
  const slotPropsFinal = useMemo(
    () => ({ ...slotProps, textField: { onBlur, ...slotProps?.textField } }),
    [slotProps, onBlur]
  )

  const dateValue = useMemo(() => {
    // Handle when value is a date
    if (isValid(value)) return value

    return value && isValid(parseISO(value.toString())) ? parseISO(value) : null
  }, [value])

  if (errors && lodashGet(errors, name)) {
    isError = true
    errorMessage = lodashGet(errors, name)?.message as string
  }

  return (
    <TimePicker
      {...props}
      inputRef={ref}
      name={name}
      error={isError}
      errorMessage={errorMessage}
      defaultValue={dateValue}
      onChange={onChange}
      slotProps={slotPropsFinal}
      value={dateValue}
    />
  )
}

export default FormTimePicker
