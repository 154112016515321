import React from 'react'

import type { CSSProperties } from 'react'

import { useSortable } from '@dnd-kit/sortable'

import { CSS } from '@dnd-kit/utilities'

import type {
  GetSortableItemStyleProps,
  SortableItemProps
} from 'components/dnd/SortableItem/SortableItem.types'

const getSortableItemStyle = ({
  transform,
  transition,
  isDragging,
  style
}: GetSortableItemStyleProps): CSSProperties => ({
  transform: CSS.Transform.toString({ ...transform, scaleX: 1, scaleY: 1 }),
  transition,
  position: 'relative',
  zIndex: isDragging ? 1 : undefined,
  cursor: isDragging ? 'grabbing' : 'grab',
  ...style
})

const SortableItem = ({ itemId, children, style }: SortableItemProps) => {
  // @dnd-kit/sortable treats falsy values as if they are undefined and breakds drag if an ID is 0.
  // We need to ensure that the ID is a string to force the library to treat it as a valid ID.
  const id = `${itemId}`

  const { attributes, isDragging, listeners, setNodeRef, transform, transition } = useSortable({
    animateLayoutChanges: () => false,
    id
  })

  const renderChildren = () => {
    if (typeof children === 'function') return children({ listeners, isDragging })

    return children
  }

  return (
    <div
      key={id}
      id={id}
      ref={setNodeRef}
      style={getSortableItemStyle({ transform, transition, isDragging, style })}
      {...attributes}>
      {renderChildren()}
    </div>
  )
}

export default SortableItem
