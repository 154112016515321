import React, { forwardRef, useCallback, useMemo } from 'react'

import {
  Box,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  RadioGroup as MuiRadioGroup,
  Radio
} from '@mui/material'

import type { RadioGroupProps } from 'components/common/buttons/RadioGroup'

import type { ValueLabelPair, ValueNodePair } from '@repo/et-types'

const rowRadioGroupStyles = {
  '.MuiFormGroup-root': { flex: 1, justifyContent: 'space-evenly' },
  '.MuiFormControlLabel-root': { margin: '0 auto 0 0 !important' }
}
const helperTextStyle = { m: '0' }

const hiddenButtonStyle = { visibility: 'hidden' }
const renderHiddenButton = () => <Radio sx={hiddenButtonStyle} />

const RadioGroup = forwardRef(
  (
    {
      label,
      values,
      name,
      required,
      disabled,
      isError,
      errorMessage,
      helperText,
      FormControlProps = {},
      RadioProps = {},
      value,
      ...props
    }: RadioGroupProps,
    ref
  ) => {
    const id = `${name}-radio-group`

    const finalFormControlSx = useMemo(
      () => ({ mt: label ? '0.5rem' : '0', ...(props?.row ? rowRadioGroupStyles : {}) }),
      [label, props?.row]
    )

    const hiddenButtonSx = useMemo(
      () => ({
        color: disabled ? 'text.disabled' : 'text.primary',
        flex: 1
      }),
      [disabled]
    )

    const labelStyle = useMemo(
      () => ({
        fontWeight: 'bold',
        color: disabled ? 'text.disabled' : 'text.primary'
      }),
      [disabled]
    )

    const itemLabelStyle = useMemo(
      () => ({
        pointerEvents: 'none',
        color: disabled ? 'text.disabled' : 'text.primary'
      }),
      [disabled]
    )

    const renderLabel = () => {
      if (!label) return null

      return <FormLabel sx={labelStyle}>{label}</FormLabel>
    }

    const renderHelperText = () => {
      if (!helperText) return null

      return <FormHelperText sx={helperTextStyle}>{helperText}</FormHelperText>
    }

    const renderError = () => {
      if (!isError || !errorMessage) return null

      return <FormHelperText className="Mui-error">{errorMessage}</FormHelperText>
    }

    const renderRadioGroupItems = useCallback(
      () =>
        values.map((e: ValueLabelPair | ValueNodePair, index: number) => (
          <Box key={index} display="flex" flexDirection="column">
            <FormControlLabel
              key={index}
              value={e.value}
              control={<Radio {...RadioProps} />}
              label={
                <>
                  {typeof e.label === 'string' ? (
                    <FormLabel sx={itemLabelStyle}>{e.label}</FormLabel>
                  ) : (
                    e.label
                  )}
                  {e.helperText && (
                    <FormHelperText sx={helperTextStyle}>{e.helperText}</FormHelperText>
                  )}
                </>
              }
              id={id}
              ref={ref}
            />
            {value === e.value && e.children && (
              <FormControlLabel
                value={e.value}
                control={renderHiddenButton()}
                sx={hiddenButtonSx}
                label={e.children}
              />
            )}
          </Box>
        )),
      [hiddenButtonSx, id, itemLabelStyle, RadioProps, ref, value, values]
    )

    return (
      <FormControl
        required={required}
        disabled={disabled}
        error={isError}
        focused={false}
        sx={finalFormControlSx}
        {...FormControlProps}>
        <Box mb=".25rem">
          {renderLabel()}
          {renderHelperText()}
        </Box>
        <MuiRadioGroup {...props} value={value}>
          {renderRadioGroupItems()}
        </MuiRadioGroup>
        {renderError()}
      </FormControl>
    )
  }
)

RadioGroup.displayName = 'RadioGroup'

export default RadioGroup
