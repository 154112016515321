import { useCallback, useMemo } from 'react'

import { get as lodashGet } from 'lodash'

import type { GridColDef } from '@mui/x-data-grid-pro'

import type { Base } from '@repo/et-types'

import type { ColumnProperty } from 'types/views'

const useColumnPropertiesAsColumnDefs = <T extends Base>({
  columnProperties,
  columnSizes
}: {
  columnProperties: ColumnProperty<T>[]
  columnSizes?: Record<string, number>
}) => {
  const getColumnWidth = useCallback(
    (column: ColumnProperty<T>) => {
      if (columnSizes && columnSizes[column.id]) return columnSizes[column.id]

      return column.width || 200
    },
    [columnSizes]
  )

  const columnDefs = useMemo(
    () =>
      columnProperties.map((column) => {
        const result: GridColDef<T> = {
          field: column.id,
          headerName: column.label,
          type: column.type,
          align: 'left',
          headerAlign: 'left',
          minWidth: 150,
          ...column,
          // We currently handle the below features in our own component.
          // We can remove these lines if we decide to use the default features.
          width: getColumnWidth(column),
          sortable: Boolean(column.sortKey),
          filterable: false,
          disableReorder: true
        }

        if (column.accessorFn) {
          result.valueGetter = (_, row) => column.accessorFn(row)
        }

        if (column.accessorKey) {
          result.valueGetter = (_, row) => {
            const value = lodashGet(row, column.accessorKey)

            return value
          }
        }

        if (column.cell) {
          result.renderCell = (params) => column.cell(params, params.row as T)
        }

        return result
      }),
    [columnProperties, getColumnWidth]
  )

  return columnDefs
}

export default useColumnPropertiesAsColumnDefs
