import React, { useMemo } from 'react'

import {
  DndContext,
  KeyboardSensor,
  MouseSensor,
  TouchSensor,
  useSensor,
  useSensors
} from '@dnd-kit/core'
import { restrictToFirstScrollableAncestor } from '@dnd-kit/modifiers'

import type { DragDropContextProps } from 'components/dnd/DragDropContext/DragDropContext.types'

const defaultModifiers = [restrictToFirstScrollableAncestor]

const DragDropContext = ({ children, ...props }: DragDropContextProps) => {
  const mouseSensor = useSensor(MouseSensor, { activationConstraint: { distance: 8 } })
  const touchSensor = useSensor(TouchSensor, {})
  const keyboardSensor = useSensor(KeyboardSensor, {})
  const sensors = useSensors(mouseSensor, touchSensor, keyboardSensor)

  // Only update the memoized sensors when the sensors array length changes
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const memoizedSensors = useMemo(() => sensors, [sensors.length])

  return (
    <DndContext sensors={memoizedSensors} modifiers={defaultModifiers} {...props}>
      {children}
    </DndContext>
  )
}

export default DragDropContext
