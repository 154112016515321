import { LicenseInfo } from '@mui/x-license'

// Get the license key from the environment variables.
export const LICENSE_KEY =
  process.env.MUI_X_LICENSE_KEY || process.env.NEXT_PUBLIC_MUI_X_LICENSE_KEY

// Set the license key for the MUI X.
if (LicenseInfo.getLicenseKey() !== LICENSE_KEY) LicenseInfo.setLicenseKey(LICENSE_KEY)

export * from './components'
export * from './contexts'
export * from './hooks'
export * from './types'
export * from './utils'
export * as Colors from './colors'

export * from './theme/constants'
