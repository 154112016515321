import React, {
  type ForwardedRef,
  forwardRef,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react'

import { Search } from '@mui/icons-material'
import { InputAdornment, TextField } from '@mui/material'

import { useDebounceValue } from 'usehooks-ts'

import { defaultSearchLabel } from 'utils/forms'
import { DEFAULT_DEBOUNCE } from 'utils/search'

import type { SearchTextFieldProps } from 'components/common/inputs/SearchTextField'

const sxStyles = (theme) => ({
  backgroundColor: theme.palette.background.paper,
  borderRadius: theme.shape.borderRadius / 6
})

const defaultId = 'search-input'
const defaultSize = 'small'

const SearchTextField = forwardRef<typeof TextField, SearchTextFieldProps>(
  (
    {
      id = defaultId,
      onValueChange,
      initialValue,
      placeholder,
      debounceDelay,
      size = defaultSize,
      ...props
    }: SearchTextFieldProps,
    ref: ForwardedRef<typeof TextField>
  ): JSX.Element => {
    const [search, setSearch] = useState(initialValue || '')
    const [debouncedText] = useDebounceValue(search, debounceDelay ?? DEFAULT_DEBOUNCE)
    const mounted = useRef(false)

    useEffect(() => {
      if (!mounted.current) {
        mounted.current = true
        return
      }

      if (onValueChange && mounted.current) onValueChange(debouncedText)
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [debouncedText])

    useEffect(() => {
      if (initialValue) setSearch(initialValue)
    }, [initialValue])

    const computedPlaceholder = useMemo(() => placeholder || defaultSearchLabel, [placeholder])

    const handleOnChange = useCallback((e) => setSearch(e.target.value), [])

    const finalSlotProps = useMemo(
      () => ({
        input: {
          startAdornment: (
            <InputAdornment position="start">
              <Search />
            </InputAdornment>
          ),
          ...props?.slotProps?.input
        }
      }),
      [props?.slotProps?.input]
    )

    return (
      <TextField
        {...props}
        inputRef={ref}
        id={id}
        size={size}
        type="search"
        hiddenLabel
        placeholder={computedPlaceholder}
        value={search}
        fullWidth
        onChange={handleOnChange}
        sx={sxStyles}
        slotProps={finalSlotProps}
      />
    )
  }
)

SearchTextField.displayName = 'SearchTextField'

export default SearchTextField
