import React from 'react'
import { useMemo } from 'react'

import { get as lodashGet } from 'lodash'
import { useController, useFormContext, useFormState } from 'react-hook-form'

import TimeRangeField from 'components/common/date/TimeRangeField'

import type { FormTimeRangeFieldProps } from 'components/forms/FormTimeRangeField/FormTimeRangeField.types'

const FormTimeRangeField = ({ name, required, slotProps, ...props }: FormTimeRangeFieldProps) => {
  const { control } = useFormContext()
  const { errors, defaultValues } = useFormState({ control, name })

  let isError = false
  let errorMessage = ''
  const defaultValue = lodashGet(defaultValues, name)
  const rules = useMemo(() => ({ required }), [required])

  const {
    field: { onChange, onBlur, value, ref }
  } = useController({ name, control, defaultValue, rules })

  const finalSlotProps = useMemo(
    () => ({ ...slotProps, textField: { onBlur, inputRef: ref, ...slotProps?.textField } }),
    [onBlur, slotProps, ref]
  )

  if (errors && lodashGet(errors, name)) {
    isError = true
    errorMessage = lodashGet(errors, name)?.message as string
  }

  return (
    <TimeRangeField
      {...props}
      ref={ref}
      error={isError}
      errorMessage={errorMessage}
      defaultValue={defaultValue}
      onChange={onChange}
      slotProps={finalSlotProps}
      value={value}
    />
  )
}

export default FormTimeRangeField
