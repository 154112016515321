import React from 'react'
import { useCallback, useMemo } from 'react'

import { get as lodashGet } from 'lodash'
import { useController, useFormContext, useFormState } from 'react-hook-form'

import Select from 'components/common/inputs/Select'

import type { FormSelectProps } from 'components/forms/FormSelect/FormSelect.types'

const FormSelect = ({
  name,
  values,
  onChange,
  required,
  ...props
}: FormSelectProps): JSX.Element => {
  const { control } = useFormContext()
  const { errors } = useFormState({ control, name })

  let isError = false
  let errorMessage = ''

  if (errors && lodashGet(errors, name)) {
    isError = true
    errorMessage = lodashGet(errors, name)?.message as string
  }

  const rules = useMemo(() => ({ required }), [required])

  const {
    field: { onChange: onValueChange, onBlur, ref, value }
  } = useController({ name, control, rules })

  const handleOnChange = useCallback(
    (event, child) => {
      onChange?.(event, child)
      onValueChange(event)
    },
    [onChange, onValueChange]
  )

  return (
    <Select
      {...props}
      error={isError}
      errorMessage={errorMessage}
      onChange={handleOnChange}
      onBlur={onBlur}
      onClose={onBlur}
      required={required}
      ref={ref}
      value={value}
      values={values}
      name={name}
    />
  )
}

export default FormSelect
