import React, { useMemo } from 'react'

import { get as lodashGet } from 'lodash'
import { useController, useFormContext, useFormState } from 'react-hook-form'

import DateRangePicker from 'components/common/date/DateRangePicker'

import type { FormDateRangePickerProps } from 'components/forms/FormDateRangePicker/FormDateRangePicker.types'

const FormDateRangePicker = ({ name, required, slotProps, ...props }: FormDateRangePickerProps) => {
  const { control } = useFormContext()
  const { errors, defaultValues } = useFormState({ control, name })

  let isError = false
  let errorMessage = ''

  if (errors && lodashGet(errors, name)) {
    isError = true
    errorMessage = lodashGet(errors, name)?.message as string
  }

  const defaultValue = lodashGet(defaultValues, name)

  const rules = useMemo(() => ({ required }), [required])
  const {
    field: { onChange, onBlur, value, ref }
  } = useController({ name, control, rules })

  const finalSlotProps = useMemo(
    () => ({
      ...slotProps,
      textField: {
        onBlur,
        required,
        ...slotProps?.textField
      }
    }),
    [slotProps, onBlur, required]
  )

  return (
    <DateRangePicker
      {...props}
      inputRef={ref}
      name={name}
      error={isError}
      errorMessage={errorMessage}
      defaultValue={defaultValue}
      onChange={onChange}
      slotProps={finalSlotProps}
      value={value}
    />
  )
}

export default FormDateRangePicker
