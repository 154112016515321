export const RELATIVE_DATE_OPTIONS = {
  TODAY: 'today',
  TOMORROW: 'tomorrow',
  YESTERDAY: 'yesterday',
  THIS_WEEK: 'this_week',
  NEXT_WEEK: 'next_week',
  LAST_WEEK: 'last_week',
  THIS_MONTH: 'this_month',
  NEXT_MONTH: 'next_month',
  LAST_MONTH: 'last_month',
  THIS_QUARTER: 'this_quarter',
  NEXT_QUARTER: 'next_quarter',
  LAST_QUARTER: 'last_quarter',
  THIS_YEAR: 'this_year',
  NEXT_YEAR: 'next_year',
  LAST_YEAR: 'last_year'
} as const

export type RelativeDate = (typeof RELATIVE_DATE_OPTIONS)[keyof typeof RELATIVE_DATE_OPTIONS]
