import type { HTMLAttributes, SyntheticEvent } from 'react'
import React, { useCallback, useEffect, useMemo, useState } from 'react'

import { ListItem, Autocomplete as MUIAutocomplete } from '@mui/material'

import { useDebounceCallback } from 'usehooks-ts'

import AutocompleteInput from 'components/common/inputs/Autocomplete/AutocompleteInput'
import ReadOnly from 'components/common/inputs/ReadOnly'

import { useDidMount } from 'hooks/useDidMount'

import { DEFAULT_DEBOUNCE } from 'utils/search'

import type { AutocompleteProps } from 'components/common/inputs/Autocomplete/Autocomplete.types'

import type {
  AutocompleteInputChangeReason,
  AutocompleteOwnerState,
  AutocompleteRenderInputParams,
  AutocompleteRenderOptionState,
  FilterOptionsState
} from '@mui/material'

import type { ValueLabelPair } from '@repo/et-types'

const defaultRole = 'comboBox'
const newId = 'new'

const defaultNone: ValueLabelPair = { label: 'None', value: null }
const defaultIsError = false
const defaultIsLoading = false
const defaultNoValue = false
const defaultReadOnlyInput = false
const defaultShouldShowDefaultValueOptions = true
const defaultShouldShowNone = false

const getOptionLabel = <T extends ValueLabelPair = ValueLabelPair>(option?: T | string) => {
  if (option) {
    if (typeof option === 'string') return option
    else if (option?.label) return option.label
  }

  return ''
}

const getOptionDisabled = <T extends ValueLabelPair = ValueLabelPair>(option: T) =>
  option && 'disabled' in option && typeof option.disabled !== 'undefined' ? option.disabled : false

const Autocomplete = <
  T extends ValueLabelPair = ValueLabelPair,
  M extends boolean | undefined = undefined,
  D extends boolean | undefined = undefined,
  F extends boolean | undefined = undefined
>({
  canCreateNew,
  createNewMessage,
  defaultValue,
  disableClearable,
  errorMessage,
  ExistingValueChip,
  freeSolo,
  helperText,
  inputRef,
  isError = defaultIsError,
  isLoading = defaultIsLoading,
  label,
  multiple,
  name,
  NewValueChip,
  noValue = defaultNoValue,
  onBlur,
  onChange,
  onCreateNew,
  onSearchChange,
  placeholder,
  readOnly,
  readOnlyInput = defaultReadOnlyInput,
  ReadOnlyProps,
  renderOption,
  required,
  shouldShowDefaultValueOptions = defaultShouldShowDefaultValueOptions,
  shouldShowNone = defaultShouldShowNone,
  size,
  TextFieldProps,
  value,
  values,
  ...props
}: AutocompleteProps<T, M, D, F>) => {
  const didMount = useDidMount()

  const initialValue = defaultValue ? defaultValue : value || multiple ? [] : null
  const [currentValue, setCurrentValue] = useState<T | T[] | null>(initialValue)

  const [options, setOptions] = useState<T[]>([])
  const [autocompleteInputValue, setAutoCompleteInputValue] = useState<string>('')

  const autocompleteValue = useMemo(() => {
    if (
      (!currentValue || (multiple && (currentValue as T[])?.length < 1)) &&
      defaultValue &&
      !didMount
    ) {
      return defaultValue
    }

    return currentValue || null
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultValue, currentValue])

  const debouncedOnSearchChange = useDebounceCallback(
    onSearchChange ? onSearchChange : () => null,
    DEFAULT_DEBOUNCE
  )

  const id = `${name}-autocomplete`

  useEffect(() => {
    if (values) {
      const singleOptions = () => {
        const finalOptions = [...values]

        if (shouldShowDefaultValueOptions) {
          if (
            defaultValue &&
            !Array.isArray(defaultValue) &&
            !finalOptions.find((op) => op.value === defaultValue?.value)
          ) {
            finalOptions.unshift(defaultValue)
          }
        }

        if (shouldShowNone) finalOptions.unshift(defaultNone as T)

        return finalOptions
      }

      const multipleOptions = () => {
        const finalOptions = [...values]

        if (shouldShowDefaultValueOptions) {
          if (defaultValue && Array.isArray(defaultValue)) {
            defaultValue.forEach((item) => {
              if (!finalOptions.find((op) => op.value === item?.value) && item) {
                finalOptions.unshift(item)
              }
            })
          }
        }

        if (shouldShowNone) finalOptions.unshift(defaultNone as T)

        return finalOptions
      }

      if (!multiple) setOptions(singleOptions())
      else setOptions(multipleOptions())
    }
  }, [values, defaultValue, multiple, shouldShowDefaultValueOptions, shouldShowNone])

  // Handle when the value changes from outside the component
  useEffect(() => {
    if (!didMount) return

    if (!value) setCurrentValue(multiple ? [] : null)
    else {
      if (multiple) {
        const newValue: T[] = (value as string[]).map((val) => {
          const option = options.find((op) => String(op.value) === String(val))

          if (option) return option as T
          else return { value: val, label: val } as T
        })

        setCurrentValue(newValue)
      } else {
        const option = options.find((op) => op.value === value)

        if (option) setCurrentValue(option)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value, multiple])

  const handleOnChange = useCallback(
    (_: SyntheticEvent, data: T | T[]) => {
      if (!Array.isArray(data) && data?.value === newId) {
        data.label = data.inputValue
        if (onCreateNew) onCreateNew(data)
      }

      const newValue =
        multiple && Array.isArray(data) ? data.map((item: T) => item?.value) : (data as T)?.value

      if (!noValue) {
        setCurrentValue(data)
        if (!multiple) setAutoCompleteInputValue((data as T)?.label || '')
      }

      return onChange((newValue as string & string[]) ?? null)
    },
    [multiple, noValue, onChange, onCreateNew]
  )

  const handleFilterOptions = useCallback(
    (options: T[], state: FilterOptionsState<T>): T[] => {
      const inputValue = state.inputValue
      const finalOptions = [...options]

      if (canCreateNew && inputValue.length > 0) {
        finalOptions.unshift({
          label: `${createNewMessage || 'Create:'} ${inputValue}`,
          inputValue: inputValue,
          value: newId
        } as T)
      }

      // if onSearchChange is defined, it means that the parent component is handling filtering
      if (onSearchChange) return finalOptions
      else {
        return finalOptions.filter((option) => {
          if (!inputValue) return true

          const label = option.label.toLowerCase()
          const optionValue = String(option.value)?.toLowerCase()

          return (
            label.includes(inputValue.toLowerCase()) ||
            optionValue?.includes(inputValue.toLowerCase())
          )
        })
      }
    },
    [canCreateNew, createNewMessage, onSearchChange]
  )

  const getOptionKey = useCallback(
    (option: string | T) => (typeof option === 'string' ? option : String(option.value)),
    []
  )

  const inputValue = useMemo(() => {
    if (multiple) return undefined

    return autocompleteInputValue
  }, [autocompleteInputValue, multiple])

  const handleOnInputChange = useCallback(
    (_, newInputValue: string, reason: AutocompleteInputChangeReason) => {
      if (onSearchChange) {
        if (reason === 'input' || reason === 'clear') debouncedOnSearchChange(newInputValue || '')
      }

      setAutoCompleteInputValue(newInputValue)
    },
    [debouncedOnSearchChange, onSearchChange]
  )

  const isOptionEqualToValue = useCallback(
    (option: T, option2: T) => String(option.value) === String(option2.value),
    []
  )

  const handleOptionRender = useCallback(
    (
      props: HTMLAttributes<HTMLLIElement> & { key: number },
      option: T,
      state: AutocompleteRenderOptionState,
      ownerState: AutocompleteOwnerState<T, M, D, F>
    ) => {
      const index: number = props['data-option-index']
      const finalProps = { ...props, 'data-testid': `${id}-option-${index}` }

      return (
        <ListItem {...finalProps} key={index}>
          {renderOption ? renderOption(props, option, state, ownerState) : option.label}
        </ListItem>
      )
    },
    [id, renderOption]
  )

  const handleRenderInput = useCallback(
    (params: AutocompleteRenderInputParams) => (
      <AutocompleteInput<T, M, D, F>
        params={params}
        currentValue={currentValue}
        TextFieldProps={TextFieldProps}
        readOnlyInput={readOnlyInput}
        label={label}
        isError={isError}
        isLoading={isLoading}
        errorMessage={errorMessage}
        helperText={helperText}
        placeholder={placeholder}
        required={required}
        size={size}
        inputRef={inputRef}
        ExistingValueChip={ExistingValueChip}
        NewValueChip={NewValueChip}
      />
    ),
    [
      currentValue,
      TextFieldProps,
      readOnlyInput,
      label,
      isError,
      isLoading,
      errorMessage,
      helperText,
      placeholder,
      required,
      size,
      inputRef,
      ExistingValueChip,
      NewValueChip
    ]
  )

  const shouldDisableClearable = useMemo<D>(
    () => (Boolean(disableClearable) || Boolean(multiple)) as D,
    [disableClearable, multiple]
  )

  if (readOnly) {
    const readOnlyValue = Array.isArray(autocompleteValue)
      ? autocompleteValue?.map((val) => {
          if (typeof val === 'string' || typeof val === 'number') return val
          else if (val?.label) return val.label
        })
      : (autocompleteValue as T)?.label

    return <ReadOnly label={label} value={readOnlyValue} {...ReadOnlyProps} />
  }

  return (
    <MUIAutocomplete<T, M, D, F>
      selectOnFocus
      disableCloseOnSelect={multiple}
      id={id}
      freeSolo={freeSolo}
      disableClearable={shouldDisableClearable}
      role={defaultRole}
      options={options}
      filterOptions={handleFilterOptions}
      inputValue={inputValue}
      isOptionEqualToValue={isOptionEqualToValue}
      getOptionLabel={getOptionLabel}
      getOptionKey={getOptionKey}
      getOptionDisabled={getOptionDisabled}
      onInputChange={handleOnInputChange}
      multiple={multiple}
      renderInput={handleRenderInput}
      // @ts-expect-error -- MUI doesn't allow us to override the type here
      // the way we need to, so we need to ignore the error.
      value={autocompleteValue}
      renderOption={handleOptionRender}
      // @ts-expect-error -- MUI doesn't allow us to override the type here
      // as we need, so we need to ignore the error.
      onChange={handleOnChange}
      onBlur={onBlur}
      {...props}
    />
  )
}

export default Autocomplete
