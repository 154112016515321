import React, { useCallback, useMemo } from 'react'

import { get as lodashGet } from 'lodash'
import { useController, useFormContext, useFormState } from 'react-hook-form'

import RadioGroup from 'components/common/buttons/RadioGroup'

import type { FormRadioGroupProps } from 'components/forms/FormRadioGroup/FormRadioGroup.types'

const FormRadioGroup = ({
  name,
  required,
  disabled,
  onValueChange,
  ...props
}: FormRadioGroupProps) => {
  const { control } = useFormContext()
  const { errors } = useFormState({ control, name })

  let isError = false
  let errorMessage = ''

  if (errors && lodashGet(errors, name)) {
    isError = true
    errorMessage = lodashGet(errors, name)?.message as string
  }

  const rules = useMemo(() => ({ required: required }), [required])
  const {
    field: { onChange, onBlur, ref, value }
  } = useController({ name, control, rules })

  const handleOnChange = useCallback(
    (e, value) => {
      onValueChange?.(value)
      onChange(e)
    },
    [onChange, onValueChange]
  )

  const finalValue = value || ''

  return (
    <RadioGroup
      {...props}
      name={name}
      isError={isError}
      errorMessage={errorMessage}
      required={required}
      disabled={disabled}
      onChange={handleOnChange}
      onBlur={onBlur}
      ref={ref}
      value={finalValue}
    />
  )
}

export default FormRadioGroup
