import JSZip from 'jszip'

export const downloadURLFilesToZip = async (
  files: { file_url: string; file_name: string }[],
  zipName: string
) => {
  const zip = new JSZip()

  const promises = []

  files.forEach((file) => {
    promises.push(
      fetch(file.file_url)
        .then((response) => response.blob())
        .then((blob) => {
          zip.file(file?.file_name, blob)
        })
    )
  })

  Promise.all(promises).then(() => {
    zip.generateAsync({ type: 'blob' }).then((content) => {
      const url = URL.createObjectURL(content)
      const a = document.createElement('a')

      a.href = url
      a.download = zipName
      a.click()
    })
  })
}
