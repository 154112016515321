import React, { useMemo } from 'react'

import { TimePicker as MUITimePicker, renderTimeViewClock } from '@mui/x-date-pickers-pro'

import { format, isValid } from 'date-fns'

import ReadOnly from 'components/common/inputs/ReadOnly'

import { useFormatDateTime } from 'hooks/useFormatDate'

import type { TimePickerProps } from 'components/common/date/TimePicker'

const timePickerViews = {
  hours: renderTimeViewClock,
  minutes: renderTimeViewClock,
  seconds: renderTimeViewClock
}

const TimePicker = ({
  defaultValue,
  error,
  errorMessage,
  fullWidth,
  helperText,
  label,
  readOnly,
  readOnlyFormatter,
  ReadOnlyProps,
  slotProps,
  value,
  ...props
}: TimePickerProps) => {
  const { use24HourClock } = useFormatDateTime()

  const finalSlotProps = useMemo(
    () => ({
      ...slotProps,
      textField: (state) => ({
        error,
        helperText: error ? errorMessage : helperText,
        fullWidth,
        ...(typeof slotProps?.textField === 'function'
          ? slotProps.textField(state)
          : slotProps?.textField)
      })
    }),
    [slotProps, error, errorMessage, helperText, fullWidth]
  )

  if (readOnly) {
    const readOnlyValue = value ?? defaultValue

    const formattedValue = () => {
      if (!isValid(readOnlyValue)) return ''

      return readOnlyFormatter ? readOnlyFormatter(readOnlyValue) : format(readOnlyValue, 'h:mm a')
    }

    return <ReadOnly label={label} value={formattedValue()} {...ReadOnlyProps} />
  }

  return (
    <MUITimePicker
      {...props}
      value={value}
      label={label}
      ampm={!use24HourClock}
      orientation="landscape"
      viewRenderers={timePickerViews}
      slotProps={finalSlotProps}
    />
  )
}

export default TimePicker
