import React, { forwardRef, useMemo } from 'react'

import { Icon } from '@iconify/react'
import Box from '@mui/material/Box'

import type { IconifyProps } from 'components/iconify/types'

import type { BoxProps } from '@mui/material/Box'

interface Props extends BoxProps {
  icon: IconifyProps
  component?: React.ElementType
}

const Iconify = forwardRef<SVGElement, Props>(({ icon, width = 20, sx, ...others }: Props, ref) => {
  const styles = useMemo(() => ({ width, height: width, ...sx }), [width, sx])

  return (
    <Box
      ref={ref}
      component={Icon}
      className="component-iconify"
      icon={icon}
      sx={styles}
      {...others}
    />
  )
})

Iconify.displayName = 'Iconify'

export default Iconify
