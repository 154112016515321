export const DIALOG_TYPES = {
  DRAWER: 'drawer',
  MODAL: 'modal'
} as const

/**
 * The types of dialogs we support.
 * @type
 */
export type DialogType = (typeof DIALOG_TYPES)[keyof typeof DIALOG_TYPES]
