import React, { useCallback, useMemo } from 'react'

import { format, isValid, parseISO } from 'date-fns'
import { get, useController, useFormContext, useFormState } from 'react-hook-form'

import DatePicker from 'components/common/date/DatePicker'

import type { FormDatePickerProps } from 'components/forms/FormDatePicker/FormDatePicker.types'

const FormDatePicker = ({
  name,
  required,
  slotProps,
  defaultValue: propDefaultValue,
  ...props
}: FormDatePickerProps) => {
  const { control } = useFormContext()
  const { errors, defaultValues } = useFormState({ control, name })

  const error = get(errors, name)
  const defaultValue = useMemo(
    () => propDefaultValue || get(defaultValues, name),
    [propDefaultValue, defaultValues, name]
  )
  const rules = useMemo(() => ({ required }), [required])

  const {
    field: { onChange, onBlur, value, ref }
  } = useController({ name, control, rules, defaultValue })

  const dateValue = value && isValid(parseISO(value.toString())) ? parseISO(value) : null

  const handleOnChange = useCallback(
    (date) => onChange(isValid(date) ? format(date, 'yyyy-MM-dd') : ''),
    [onChange]
  )

  const finalSlotProps = useMemo(
    () => ({
      ...slotProps,
      textField: {
        onBlur,
        required,
        ...slotProps?.textField
      }
    }),
    [slotProps, onBlur, required]
  )

  return (
    <DatePicker
      {...props}
      inputRef={ref}
      name={name}
      error={!!error}
      errorMessage={error?.message}
      defaultValue={dateValue}
      onChange={handleOnChange}
      slotProps={finalSlotProps}
      value={dateValue}
    />
  )
}

export default FormDatePicker
