import React, { type ForwardedRef, forwardRef } from 'react'

import { FormControlLabel, FormGroup, Switch as MuiSwitch } from '@mui/material'

import type { SwitchProps } from 'components/common/inputs/Switch'

const Switch = forwardRef(
  (
    { disabled, label, onChange, value, ...others }: SwitchProps,
    ref: ForwardedRef<HTMLButtonElement>
  ): JSX.Element => (
    <FormGroup>
      <FormControlLabel
        disabled={disabled}
        value={value}
        control={
          <MuiSwitch
            ref={ref}
            onChange={onChange}
            value={value}
            checked={Boolean(value)}
            color="primary"
            {...others}
          />
        }
        label={label}
      />
    </FormGroup>
  )
)

Switch.displayName = 'Switch'

export default Switch
