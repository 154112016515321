import React, { useCallback, useMemo } from 'react'

import { Box, Paper, Stack } from '@mui/material'

import HorizontalStepper from 'components/common/layout/HorizontalStepper'

import DialogFormFooter from 'components/forms/DialogForm/DialogFormFooter'
import DialogFormHeader from 'components/forms/DialogForm/DialogFormHeader'
import FormBase from 'components/forms/FormBase'

import { DIALOG_TYPES } from 'types/dialog'
import { defaultDiscardLabel, defaultSubmitLabel } from 'utils/forms'

import type { DialogFormProps } from 'components/forms/DialogForm/DialogForm.types'

import type { SxProps } from '@mui/material'
import type { Theme } from '@mui/material/styles'

import type { FieldValues } from 'react-hook-form'

const contentWrapperStyles: SxProps<Theme> = { bgcolor: (theme) => theme.palette.background.paper }
const defaultHeaderBoxStyles: SxProps<Theme> = {
  position: 'sticky',
  top: 0,
  left: 0,
  zIndex: 1205 // setting to 1205 to be above all other elements in the dialog since html editor is 1201 and Mui drawer is 1200
}
const horizontalStepperStyles: SxProps<Theme> = { height: '100%' }

/**
 * The base component for a form in a dialog. Current dialogs supported are:
 * Drawer & Modal.
 * @component
 */
const DialogForm = <T extends FieldValues>({
  bodyStackProps,
  children = null,
  component,
  DialogFormFooterProps,
  DialogFormHeaderProps,
  FormBaseProps,
  discardButtonProps,
  discardButtonLabel = defaultDiscardLabel,
  footerComponent = undefined,
  footerBoxProps = undefined,
  formProps = undefined,
  headerComponent = undefined,
  headerBoxProps = undefined,
  headerLabel = undefined,
  HorizontalStepperProps = undefined,
  isLoading = false,
  methods = undefined,
  noPadding = false,
  onClose,
  onDiscard = undefined,
  onSubmit = undefined,
  reverseButtons = false,
  submitButtonProps,
  submitButtonLabel = defaultSubmitLabel,
  variant = DIALOG_TYPES.DRAWER,
  contentWrapperProps,
  withStyles = true
}: DialogFormProps<T>) => {
  const handleDiscardClick = useCallback((): void => {
    if (onDiscard) onDiscard()

    if (methods) methods.reset()
  }, [methods, onDiscard])

  const shouldRenderFooter = onSubmit || onDiscard || footerComponent
  const shouldRenderHeader = headerLabel || headerComponent

  const padding = useMemo(() => {
    if (noPadding) return { px: 0, py: 0 }
    else if (variant === DIALOG_TYPES.DRAWER) return { px: 4, py: 2 }

    return { px: 2, pt: shouldRenderHeader ? 0 : 2, pb: shouldRenderFooter ? 0 : 2 }
  }, [noPadding, shouldRenderFooter, shouldRenderHeader, variant])

  const headerBoxStyles = useMemo(
    () => ({ ...defaultHeaderBoxStyles, ...headerBoxProps?.sx }),
    [headerBoxProps?.sx]
  )

  return (
    <FormBase
      methods={methods}
      component={component}
      formProps={formProps}
      withStyles={withStyles}
      {...FormBaseProps}>
      <Stack height="100%" {...contentWrapperProps} sx={contentWrapperStyles}>
        {shouldRenderHeader &&
          (headerComponent ? (
            <Box {...headerBoxProps} component={Paper} sx={headerBoxStyles}>
              {headerComponent}
            </Box>
          ) : (
            <DialogFormHeader
              headerLabel={headerLabel}
              variant={variant}
              onClose={onClose}
              {...DialogFormHeaderProps}
            />
          ))}

        <Stack flex="1" sx={padding} {...bodyStackProps}>
          {HorizontalStepperProps ? (
            <HorizontalStepper {...HorizontalStepperProps} sx={horizontalStepperStyles} />
          ) : null}
          {children}
        </Stack>

        {shouldRenderFooter &&
          (footerComponent ? (
            <Box {...footerBoxProps}>{footerComponent}</Box>
          ) : (
            <DialogFormFooter
              discardButtonProps={discardButtonProps}
              discardButtonLabel={discardButtonLabel}
              isLoading={isLoading}
              onDiscard={handleDiscardClick}
              onSubmit={onSubmit}
              submitButtonProps={submitButtonProps}
              submitButtonLabel={submitButtonLabel}
              variant={variant}
              reverseButtons={reverseButtons}
              {...DialogFormFooterProps}
            />
          ))}
      </Stack>
    </FormBase>
  )
}

export default DialogForm
