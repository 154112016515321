import React, { useMemo } from 'react'

import { Button } from '@mui/material'

import type { ButtonProps, SxProps, Theme } from '@mui/material'

const buttonStyleOverrides: SxProps<Theme> = {
  padding: 0,
  height: 'unset',
  maxHeight: 'unset',
  textTransform: 'none',
  textAlign: 'start'
}

/**
 * The Clickable is a stripped down version of the Button component.
 * It is used to make custom clickable components that also support hover & focus states.
 * @param children - The content of the button.
 * @param sx - The style props of the button.
 * @param props - The props of the button.
 */
const Clickable = (props: ButtonProps) => {
  const finalSxProps = useMemo(() => ({ ...buttonStyleOverrides, ...props?.sx }), [props?.sx])

  return (
    <Button variant="text" color="inherit" {...props} sx={finalSxProps}>
      {props?.children}
    </Button>
  )
}

export default Clickable
