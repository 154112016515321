import React from 'react'
import { useCallback, useMemo } from 'react'

import { get as lodashGet } from 'lodash'
import { useController, useFormContext, useFormState } from 'react-hook-form'

import Autocomplete from 'components/common/inputs/Autocomplete'

import type { FormAutocompleteProps } from 'components/forms/FormAutocomplete/FormAutocomplete.types'

import type { ValueLabelPair } from '@repo/et-types'

const FormAutocomplete = <
  M extends boolean | undefined = false,
  D extends boolean | undefined = false,
  F extends boolean | undefined = false
>({
  name,
  required,
  values,
  defaultValue,
  onChange,
  multiple,
  disableClearable,
  freeSolo,
  ...props
}: FormAutocompleteProps<M, D, F>) => {
  const { control } = useFormContext()
  const { errors } = useFormState({ control, name })

  let isError = false
  let errorMessage = ''

  if (errors && lodashGet(errors, name)) {
    isError = true
    errorMessage = lodashGet(errors, name)?.message as string
  }

  const getDefaultValue = useCallback(
    () =>
      typeof defaultValue === 'string' ? defaultValue : (defaultValue as ValueLabelPair)?.value,
    [defaultValue]
  )

  const initialDefault = multiple
    ? (defaultValue as ValueLabelPair[])
    : (defaultValue as ValueLabelPair)

  const rules = useMemo(() => ({ required }), [required])

  const {
    field: { onChange: onValueChange, onBlur, value, ref }
  } = useController({ name, control, defaultValue: getDefaultValue(), rules })

  const handleOnChange = useCallback(
    (newValue) => {
      onChange?.(newValue)
      onValueChange(newValue)
    },
    [onChange, onValueChange]
  )

  return (
    <Autocomplete
      isError={isError}
      defaultValue={initialDefault}
      errorMessage={errorMessage}
      disableClearable={disableClearable}
      freeSolo={freeSolo}
      required={required}
      name={name}
      values={values}
      onChange={handleOnChange}
      inputRef={ref}
      onBlur={onBlur}
      value={value}
      multiple={multiple}
      {...props}
    />
  )
}

export default FormAutocomplete
