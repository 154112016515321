import { useMemo } from 'react'

import { GRID_CHECKBOX_SELECTION_COL_DEF, GRID_REORDER_COL_DEF } from '@mui/x-data-grid-pro'

import useColumnPropertiesAsColumnDefs from 'hooks/dataGrid/useColumnPropertiesAsColumnDefs'
import useDataGridActions from 'hooks/dataGrid/useDataGridActions'

import type { GridColDef } from '@mui/x-data-grid-pro'

const selectionColumn: GridColDef = {
  ...GRID_CHECKBOX_SELECTION_COL_DEF,
  maxWidth: 40,
  minWidth: 40
}

const defaultReorderColumn: GridColDef = {
  ...GRID_REORDER_COL_DEF,
  maxWidth: 40,
  minWidth: 40
}

const useDataGridColumns = ({
  actions,
  actionsDropdownProps,
  columnProperties,
  columnSizes,
  customActionsColumn,
  customReorderColumn,
  inlineActions
}) => {
  const columnPropertyColumns = useColumnPropertiesAsColumnDefs({
    columnProperties,
    columnSizes
  })

  const actionsColumn = useDataGridActions({
    actions,
    actionsDropdownProps,
    customActionsColumn,
    inline: inlineActions
  })

  // Memoize the columns to prevent their size from being recalculated on every render.
  const columns = useMemo(
    () => {
      const result = [
        customReorderColumn || defaultReorderColumn,
        selectionColumn,
        ...columnPropertyColumns
      ]

      if (actions?.length > 0 || customActionsColumn) result.push(actionsColumn)

      return result
    },
    // Disabling this rule because we want to only recalculate the columns when:
    // - The column properties change by length (This indicates a new column was added or removed)
    // - The actions change by length (This indicates a new action was added or removed)
    // - The actions column changes (This indicates the actions column was updated)
    // - The custom actions column changes (This indicates the custom actions column was updated)
    // - The custom reorder column changes (This indicates the custom reorder column was updated)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      actions,
      actionsColumn,
      customActionsColumn,
      customReorderColumn,
      columnPropertyColumns?.length
    ]
  )

  return columns
}

export default useDataGridColumns
