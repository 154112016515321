import React, { useCallback, useMemo } from 'react'

import { renderTimeViewClock } from '@mui/x-date-pickers-pro'
import { isValid } from 'date-fns'
import { get, useController, useFormContext, useFormState } from 'react-hook-form'

import DateTimePicker from 'components/common/date/DateTimePicker'

import type { FormDateTimePickerProps } from 'components/forms/FormDateTimePicker/FormDateTimePicker.types'

const viewProps = {
  hours: renderTimeViewClock,
  minutes: renderTimeViewClock,
  seconds: renderTimeViewClock
}

const FormDateTimePicker = ({ name, required, slotProps, ...props }: FormDateTimePickerProps) => {
  const { control } = useFormContext()
  const { errors, defaultValues } = useFormState({ control, name })

  const error = get(errors, name)
  const defaultValue = get(defaultValues, name)
  const isError = !!error

  const rules = useMemo(() => ({ required }), [required])
  const {
    field: { onChange, onBlur, value, ref }
  } = useController({ name, control, rules, defaultValue })

  const dateTimeValue = value ? new Date(value) : null

  const handleOnChange = useCallback(
    (dateTime) => {
      const validDateTime = isValid(dateTime)

      onChange(validDateTime ? dateTime.toISOString() : dateTime)
    },
    [onChange]
  )

  const finalSlotProps = useMemo(
    () => ({
      ...slotProps,
      textField: { onBlur, required, ...slotProps?.textField }
    }),
    [slotProps, onBlur, required]
  )

  return (
    <DateTimePicker
      {...props}
      inputRef={ref}
      name={name}
      error={isError}
      errorMessage={error?.message}
      defaultValue={dateTimeValue}
      onChange={handleOnChange}
      slotProps={finalSlotProps}
      value={dateTimeValue}
      viewRenderers={viewProps}
    />
  )
}

export default FormDateTimePicker
