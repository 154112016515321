import React, { useCallback } from 'react'

import MenuItem from '@mui/material/MenuItem'
import Select from '@mui/material/Select'

import { dateOptionLabels } from 'utils/dateOptionLabels'

import type { RelativeDatePickerProps } from 'components/common/date/RelativeDatePicker/RelativeDatePicker.types'

import type { RelativeDate } from 'types/dates'

const RelativeDatePicker = ({
  label,
  relativeDate,
  onRelativeDateChange,
  options = dateOptionLabels,
  SelectProps
}: RelativeDatePickerProps): JSX.Element => {
  const handleOnChange = useCallback(
    (event) => onRelativeDateChange?.(event.target.value as RelativeDate),
    [onRelativeDateChange]
  )
  const value = relativeDate ?? ''

  return (
    <Select {...SelectProps} label={label} onChange={handleOnChange} value={value}>
      {options.map((option) => (
        <MenuItem key={option.value} value={option.value}>
          {option.label}
        </MenuItem>
      ))}
    </Select>
  )
}

export default RelativeDatePicker
