import React, { forwardRef } from 'react'

import { Add } from '@mui/icons-material'

import { Button } from '@mui/material'

import type { AddButtonProps } from 'components/common/buttons/AddButton/AddButton.types'

const AddButton = forwardRef<HTMLButtonElement, AddButtonProps>(
  (
    {
      children,
      label,
      color = 'primary',
      variant = 'contained',
      size = 'medium',
      ...others
    }: AddButtonProps,
    ref
  ) => (
    <Button variant={variant} color={color} size={size} startIcon={<Add />} ref={ref} {...others}>
      {children || label}
    </Button>
  )
)

AddButton.displayName = 'AddButton'

export default AddButton
