import React from 'react'
import { useCallback, useLayoutEffect, useMemo, useRef } from 'react'

import { Delete, Edit, MoreHoriz, Reply } from '@mui/icons-material'

import {
  Avatar,
  Box,
  Card,
  CardContent,
  CardHeader,
  Chip,
  Divider,
  IconButton,
  Stack,
  Tooltip,
  Typography
} from '@mui/material'

import sanitizeHtml from 'sanitize-html'

import { useToggle } from 'usehooks-ts'

import MailHeaderCell from 'components/mail/Mail/MailHeaderCell'
import MailAttachments from 'components/mail/MailAttachments/MailAttachments'

import { generateColorFromString } from 'utils/styleUtils'

import type { MailProps } from 'components/mail/Mail/Mail.types'

import type { Theme } from '@mui/material'

const generateEmailsString = (emails: { name: string; email: string }[]) =>
  emails.map((email) => (email?.name ? `${email?.name} <${email.email}>` : email.email)).join(', ')

const stringAvatar = (name: string) => ({
  sx: { bgcolor: generateColorFromString(name?.toUpperCase()) },
  children: `${name.split(' ')?.[0]?.[0]}`.toUpperCase()
})

const cardStyles = (wasReceived: boolean = false) => ({
  // Styles for the blockquote inside the mail body
  'img[src=""],img:not([src])': { height: '1.25rem' },
  'div:has(blockquote)': { my: 0.5 },
  blockquote: {
    borderLeft: (theme: Theme) => `2px solid ${theme.palette.grey[300]}`,
    margin: 0,
    paddingLeft: 4
  },
  // Styles for the mail card
  alignSelf: wasReceived ? 'flex-end' : 'flex-start',
  width: '85%',
  '.MuiCardContent-root': { '&:last-child': { pb: 1 } }
})
const cardContentStyles = { px: 2, py: 0 }
const avatarStyles = (theme: Theme) => ({ border: 1, borderColor: theme.palette.grey[400] })
const dividerStyles = { mt: 1, mx: 2 }

const headerStyles = {
  px: 2,
  pt: 2,
  overflow: 'hidden',
  '& .MuiCardHeader-content': { overflow: 'hidden', pr: 1 }
}

// Allowed tags to be rendered in the mail body
const allowedTags = sanitizeHtml.defaults.allowedTags.concat(['img', 'blockquote'])

// Allowed attributes for the tags in the mail body
const allowedAttributes = {
  ...sanitizeHtml.defaults.allowedAttributes,
  img: [...sanitizeHtml.defaults.allowedAttributes.img, 'style']
}

const defaultLabels = {
  attachments: 'Attachments',
  bcc: 'BCC',
  cc: 'CC',
  draft: 'Draft',
  from: 'From',
  subject: 'Subject',
  to: 'To'
}

const MAX_HEIGHT = 200 // 12.5rem

const Mail = ({
  canDelete = true,
  canEdit,
  canReply,
  children,
  deleteButtonProps,
  labels: labelsProp = defaultLabels,
  mail,
  onEditClick,
  onReplyClick,
  onDeleteClick,
  editButtonProps,
  replyButtonProps
}: MailProps) => {
  const cardContentRef = useRef<HTMLDivElement>(null)

  const [showMore, toggleMore] = useToggle(false)
  const [showMoreButton, toggleMoreButton] = useToggle(true)

  const labels = { ...defaultLabels, ...labelsProp }

  const renderChildren = () =>
    children ? (typeof children === 'function' ? children({ mail }) : children) : null

  const showBccEmails = mail?.bcc_emails?.length > 0
  const showCcEmails = mail?.cc_emails?.length > 0

  const hasAttachments = mail?.draft_attachments?.length > 0

  const isDraft = mail?.state === 'draft'
  const wasReceived = mail?.state === 'received'

  const hasEditButton = useMemo(() => {
    if (isDraft && typeof canEdit === 'undefined') return true

    if (typeof canEdit === 'function') return canEdit(mail)

    return canEdit
  }, [canEdit, isDraft, mail])

  const hasReplyButton = useMemo(() => {
    const showButton = Boolean(mail?.state !== 'bounced') && Boolean(mail?.state !== 'draft')

    if (showButton && typeof canReply === 'undefined') return true

    if (typeof canReply === 'function') return canReply(mail)

    return canReply
  }, [canReply, mail])

  const hasDeleteButton = typeof canDelete === 'function' ? canDelete(mail) : canDelete

  const from = mail?.from_emails?.[0] || mail?.user

  const avatarProps = useMemo(() => {
    if (mail?.user?.logo_thumb_url && !wasReceived) return { src: mail?.user?.logo_thumb_url }

    return stringAvatar(from?.name || from?.email)
  }, [from, mail, wasReceived])

  // Check if the mail content is bigger than the max height
  // If it is, show the "Show more" button. Has to be in a
  // useLayoutEffect because the cardContentRef is not available
  // in the first render.
  useLayoutEffect(() => {
    if (cardContentRef.current) {
      const { scrollHeight } = cardContentRef.current

      if (scrollHeight < MAX_HEIGHT) toggleMoreButton()
    }
  }, [toggleMoreButton, cardContentRef])

  const mailBodyStyles = useMemo(
    () => ({ px: 2, py: 1, overflow: 'hidden', maxHeight: showMore ? 'auto' : '12.5rem' }),
    [showMore]
  )
  const mailBodyContent = useMemo(
    () => ({ __html: sanitizeHtml(mail.body, { allowedTags, allowedAttributes }) }),
    [mail.body]
  )

  const handleEditClick = useCallback(() => onEditClick(mail), [mail, onEditClick])
  const handleReplyClick = useCallback(() => onReplyClick(mail), [mail, onReplyClick])
  const handleDeleteClick = useCallback(() => onDeleteClick(mail), [mail, onDeleteClick])

  return (
    <Card data-testid="Mail" sx={cardStyles(wasReceived)}>
      <CardHeader
        sx={headerStyles}
        action={
          <Stack direction="row" columnGap={0.5} alignItems="center" justifyContent="flex-end">
            {isDraft ? (
              <Chip color="warning" label={labels?.draft} variant="soft" size="small" />
            ) : null}
            {hasEditButton ? (
              <Tooltip arrow title="Edit">
                <IconButton
                  data-testid="MailEditButton"
                  size="small"
                  onClick={handleEditClick}
                  {...editButtonProps}>
                  {editButtonProps?.children || <Edit fontSize="inherit" />}
                </IconButton>
              </Tooltip>
            ) : null}
            {hasReplyButton ? (
              <Tooltip arrow title="Reply">
                <IconButton
                  data-testid="MailReplyButton"
                  size="small"
                  onClick={handleReplyClick}
                  {...replyButtonProps}>
                  {replyButtonProps?.children || <Reply fontSize="inherit" />}
                </IconButton>
              </Tooltip>
            ) : null}
            {hasDeleteButton ? (
              <Tooltip arrow title="Delete">
                <IconButton
                  data-testid="MailDeleteButton"
                  size="small"
                  onClick={handleDeleteClick}
                  {...deleteButtonProps}>
                  {deleteButtonProps?.children || <Delete fontSize="inherit" />}
                </IconButton>
              </Tooltip>
            ) : null}
          </Stack>
        }
        disableTypography
        title={
          <Stack flex={1} direction="row" alignItems="center" mb={1} columnGap={1}>
            <Avatar sx={avatarStyles} {...avatarProps} />
            <Typography component="span" flex={1} color="text.secondary" noWrap>
              <Typography color="text.primary" fontWeight="bold">
                {from?.name ? from?.name : from?.email}
              </Typography>
              {from?.email && from?.name ? from?.email : ''}
            </Typography>
          </Stack>
        }
        subheader={
          <Stack>
            <MailHeaderCell
              prefix={labels.to}
              value={generateEmailsString(mail?.to_emails || [])}
            />
            {showBccEmails ? (
              <MailHeaderCell prefix={labels.bcc} value={generateEmailsString(mail?.bcc_emails)} />
            ) : null}
            {showCcEmails ? (
              <MailHeaderCell prefix={labels.cc} value={generateEmailsString(mail?.cc_emails)} />
            ) : null}
            <MailHeaderCell variant="body1" mt={1} prefix={labels.subject} value={mail?.subject} />
          </Stack>
        }
      />
      <Divider sx={dividerStyles} />
      <CardContent ref={cardContentRef} sx={mailBodyStyles}>
        <Box dangerouslySetInnerHTML={mailBodyContent} />
      </CardContent>
      {showMoreButton ? (
        <Box ml={1} my={1}>
          <IconButton size="small" onClick={toggleMore}>
            <MoreHoriz />
          </IconButton>
        </Box>
      ) : null}
      {hasAttachments ? (
        <CardContent sx={cardContentStyles}>
          <MailAttachments attachments={mail.draft_attachments} />
        </CardContent>
      ) : null}
      <CardContent sx={cardContentStyles}>{renderChildren()}</CardContent>
    </Card>
  )
}

export default Mail
