import { alpha } from '@mui/material'
import { amber, orange } from '@mui/material/colors'

const tentative = {
  /**
   * Main color
   * Used for contained background
   */
  MAIN: amber[200],
  /**
   * Text color
   */
  CONTENT: orange[900],
  /**
   * Color that keeps a contrast ratio above AA when XX.main is used as a bg. color
   */
  CONTRAST: 'white',
  /**
   * Fill background color for contained variant components in hover state
   */
  CONTAINED_HOVER_BACKGROUND: amber[400],
  /**
   * Fill background color for outlined in hover state
   */
  OUTLINED_HOVER_BACKGROUND: alpha(amber[400], 0.16),
  /**
   * Used for outlined variant components in resting state
   */
  OUTLINED_RESTING_BORDER: amber[600]
}

export default tentative
